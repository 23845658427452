import { Episode } from 'domain/igniteTitle/details/catalogue/configuration';
import {
  hasNonEmptyArrayPropertyWithVerify,
  isArrayPropertyWithVerify,
  isNumber,
  isPropertyWithVerify,
  isString,
} from '../../../../guards';

export interface TitleStructure {
  versions: TitleStructureVersion[];
}

export const validateTitleStructure = (argument: TitleStructure) => {
  isArrayPropertyWithVerify(
    argument.versions,
    validateTitleStructureVersions,
    'Invalid TitleStructure type, versions is not a TitleStructureVersion[]'
  );

  return true;
};

export interface TitleStructureRow
  extends Pick<
    Episode,
    'slotLengthMinutes' | 'approximateRuntimeMinutes' | 'name' | 'id'
  > {
  rowIndex: number;
  episodeCount: number;
}

export const validateTitleStructureRow = (argument: TitleStructureRow) => {
  isPropertyWithVerify(
    argument.rowIndex,
    isNumber,
    'Invalid TitleStructureRow type, rowIndex is not a number'
  );
  isPropertyWithVerify(
    argument.episodeCount,
    isNumber,
    'Invalid TitleStructureRow type, episodeCount is not a number'
  );
  if (argument.slotLengthMinutes) {
    isPropertyWithVerify(
      argument.slotLengthMinutes,
      isNumber,
      'Invalid TitleStructureRow type, slotLengthMinutes is not a number'
    );
  }
  if (argument.approximateRuntimeMinutes) {
    isPropertyWithVerify(
      argument.approximateRuntimeMinutes,
      isNumber,
      'Invalid TitleStructureRow type, approximateRuntimeMinutes is not a number'
    );
  }

  return true;
};

export interface TitleStructureVersion {
  name: string;
  versionIndex: number;
  rows: TitleStructureRow[];
}

export const validateTitleStructureVersions = (
  argument: TitleStructureVersion
) => {
  isPropertyWithVerify(
    argument.name,
    isString,
    'Invalid TitleStructureVersion type, name is not a string'
  );
  isPropertyWithVerify(
    argument.versionIndex,
    isNumber,
    'Invalid TitleStructureVersion type, versionIndex is not a number'
  );
  hasNonEmptyArrayPropertyWithVerify(
    argument,
    'titleStructureVersion',
    'rows',
    validateTitleStructureRow,
    'titleStructureRow'
  );

  return true;
};
