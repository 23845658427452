import { format } from 'date-fns';
import {
  DateUnitTypes,
  DateWithUnitType,
  ProductionDateRange,
  StartDateRange,
} from 'domain/igniteTitle/details/date';
import { getUTCDateFromISOString } from './getUTCDateFromISOString';

const formatDateWithMode = (dateWithMode?: DateWithUnitType) => {
  if (!dateWithMode) {
    return '-';
  }

  const { unit, date } = dateWithMode;
  const nativeDate = getUTCDateFromISOString(date);

  switch (unit) {
    case DateUnitTypes.DATE:
      return format(nativeDate, 'MMM dd, yyyy');

    case DateUnitTypes.MONTH:
      return format(nativeDate, 'MMM, yyyy');

    case DateUnitTypes.YEAR:
      return format(nativeDate, 'yyyy');

    case DateUnitTypes.QUARTER:
      return format(nativeDate, 'QQQ, yyyy');

    default:
      return '-';
  }
};

const formatDateRangeWithMode = (
  dateRange?: Nullable<StartDateRange> | Nullable<ProductionDateRange>
) => {
  if (!dateRange?.start && dateRange?.end)
    return formatDateWithMode(dateRange.end);
  if (!dateRange?.end && dateRange?.start)
    return formatDateWithMode(dateRange.start);

  if (dateRange?.start && dateRange?.end)
    return `${formatDateWithMode(dateRange.start)} - ${formatDateWithMode(
      dateRange.end
    )}`;

  return '-';
};

export { formatDateWithMode, formatDateRangeWithMode };
