import { hasNonEmptyStringProperty } from '../../../guards';

export interface AlternateName {
  id: string;
  name: string;
}

export const validateAlternateName = (argument: AlternateName) => {
  hasNonEmptyStringProperty(argument, 'AlternateName', 'id');
  hasNonEmptyStringProperty(argument, 'AlternateName', 'name');
  return true;
};
