import { Container } from 'components/Layout';
import { useUserProfile } from 'hooks/useUserProfile';
import { NavInfo } from './NavInfo';
import { NavActions } from './NavActions';

export const Nav = () => {
  const { data: userProfile } = useUserProfile();
  return (
    <header>
      <Container
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingX: 3,
        }}
      >
        <NavInfo />
        <NavActions canCreate={userProfile?.permissions.canCreate} />
      </Container>
    </header>
  );
};
