import { hasNonEmptyStringProperty, validateNotNull } from '../../../../guards';

export interface CatalogueBrandInfo {
  name: string;
  ccid?: string;
}

export const validateCatalogueBrandInfo = (argument: any) => {
  validateNotNull(argument, `Invalid CatalogueBrandInfo type, object is null`);
  hasNonEmptyStringProperty(argument, 'catalogueBrandInfo', 'name');
  if (argument.ccid != null) {
    hasNonEmptyStringProperty(argument, 'catalogueBrandInfo', 'ccid');
  }
  return true;
};
