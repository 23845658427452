import ReactGA from 'react-ga4';
import { getConfig } from 'utils/config';

enum EventNames {
  EditTitle = 'edit-title',
  SearchTitle = 'search-title',
  FilterTitle = 'filter-title',
  Archive = 'archive',
  StateHistory = 'state-history',
}

enum EventCategories {
  EditTitle = 'Edit Title',
  EditBrand = 'Edit Brand',
  DashboardAction = 'Dashboard Action',
  StateHistory = 'State History',
}

enum EditTitleOrBrandActions {
  Unlock = 'unlock',
  Confirm = 'confirm',
  Edit = 'edit',
  Save = 'save',
}

enum DashboardActions {
  Search = 'search',
  Archive = 'archive',
}

enum StateHistoryActions {
  Next = 'next',
  Previous = 'previous',
}

export const EventActions = {
  EditTitleOrBrandActions,
  DashboardActions,
  StateHistoryActions,
};

const eventPrefix = 'AC-';
const measurementId = 'G-NQ32DNRFNH';

export const useGoogleAnalytics = () => {
  const env = getConfig().ENVIRONMENT;
  const initGA = (): void =>
    ReactGA.initialize(measurementId, {
      testMode: env !== 'prd',
    });

  const eventLoggerFactory = (
    eventName: EventNames,
    category: EventCategories
  ) => {
    return (
      action: EditTitleOrBrandActions | DashboardActions | string,
      label?: string | boolean
    ) => {
      ReactGA.event(`${eventPrefix}${eventName}`, {
        action,
        category,
        label,
      });
    };
  };

  const logEditTitleEvent = eventLoggerFactory(
    EventNames.EditTitle,
    EventCategories.EditTitle
  );
  const logEditBrandEvent = eventLoggerFactory(
    EventNames.EditTitle,
    EventCategories.EditBrand
  );

  const logDashboardSearchEvent = eventLoggerFactory(
    EventNames.SearchTitle,
    EventCategories.DashboardAction
  );

  const logDashboardFilterEvent = eventLoggerFactory(
    EventNames.FilterTitle,
    EventCategories.DashboardAction
  );

  const logDashboardArchiveEvent = eventLoggerFactory(
    EventNames.Archive,
    EventCategories.DashboardAction
  );

  const logStateHistoryEvent = eventLoggerFactory(
    EventNames.StateHistory,
    EventCategories.StateHistory
  );

  // eslint-disable-next-line no-underscore-dangle
  const hasLoadedGA = (): boolean => ReactGA._hasLoadedGA;

  return {
    initGA,
    hasLoadedGA,
    logEditTitleEvent,
    logEditBrandEvent,
    logDashboardSearchEvent,
    logDashboardFilterEvent,
    logDashboardArchiveEvent,
    logStateHistoryEvent,
  };
};
