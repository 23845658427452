import { isPropertyWithVerify, isString, validateNotNull } from 'domain/guards';

export type AmortisationProfile = {
  key: string;
  description: string;
};

export const validateAmortisationProfile = (
  argument: AmortisationProfile
): boolean => {
  validateNotNull(argument, `Invalid AmortisationProfile type, object is null`);
  isPropertyWithVerify(
    argument.key,
    isString,
    `Invalid AmortisationProfile type, key is not a string`
  );
  isPropertyWithVerify(
    argument.description,
    isString,
    `Invalid AmortisationProfile type, description is not a string`
  );

  return true;
};
