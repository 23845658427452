import { SortIndicator } from 'features/SearchAndFilter';
import { SortBy } from 'hooks/useSort';
import { styled } from '@mui/material';
import { colours } from 'utils/theme/colours';

const TableRow = styled('tr')`
  display: flex;
  align-items: center;
`;

interface StyleProps {
  flexWidth: number;
}
const TableHeading = styled('th')<StyleProps>`
  text-align: left;
  ${(props) =>
    props.flexWidth && `flex: 0 0 calc(100% / (12 / ${props.flexWidth}))`};
  color: ${colours.secondary.main};
  background-color: initial;
  padding: 0.75rem 0.75rem 0.15rem;
  font-size: 0.8125rem;
  font-weight: 400 !important;
  vertical-align: bottom;
  text-align: left;
`;

interface Props {
  resultsOfASearch?: boolean;
}

interface SortableProps {
  isSortable: boolean;
  sortKey: SortBy;
  label: string;
}
const Sortable = ({ isSortable, sortKey, label }: SortableProps) => {
  if (isSortable)
    return (
      <div>
        <SortIndicator sortKey={sortKey} label={label}>
          <span>{label}</span>
        </SortIndicator>
      </div>
    );
  return <span>{label}</span>;
};

const TitlesTableHeader = ({ resultsOfASearch }: Props) => (
  <thead>
    <TableRow>
      <TableHeading flexWidth={3}>
        <Sortable
          isSortable={!resultsOfASearch}
          sortKey={SortBy.ProjectName}
          label="Project Name"
        />
      </TableHeading>
      <TableHeading flexWidth={2}>Producer(s)</TableHeading>
      <TableHeading flexWidth={2}>Status</TableHeading>
      <TableHeading flexWidth={2}>Transmission</TableHeading>
      <TableHeading flexWidth={1}>
        <Sortable
          isSortable={!resultsOfASearch}
          sortKey={SortBy.TargetDate}
          label="Review"
        />
      </TableHeading>
      <TableHeading flexWidth={2}>
        <Sortable
          isSortable={!resultsOfASearch}
          sortKey={SortBy.ModifiedAt}
          label="Last update"
        />
      </TableHeading>
    </TableRow>
  </thead>
);

export { TitlesTableHeader };
