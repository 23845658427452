/* eslint-disable no-underscore-dangle */

import { envConfig } from './loadEnvConfig';

const location =
  typeof window === 'undefined'
    ? 'http://localhost:12345'
    : window.location.origin;

const commonOktaFields = {
  redirectUri: `${location}/okta/code`,
  pkce: false,
  scopes: ['openid', 'profile', 'email', 'groups', 'offline_access'],
  responseType: 'code',
};

export const getOktaConfig = () => {
  if (process.env.NODE_ENV === 'test') {
    return {
      issuer: envConfig?.OKTA_ISSUER,
      clientId: envConfig?.OKTA_CLIENTID,
      ...commonOktaFields,
    };
  }

  if (window._env_) {
    return {
      issuer: window._env_.OKTA_ISSUER,
      clientId: window._env_.OKTA_CLIENTID,
      ...commonOktaFields,
    };
  }

  return {
    issuer: envConfig?.OKTA_ISSUER,
    clientId: envConfig?.OKTA_CLIENTID,
    ...commonOktaFields,
  };
};
