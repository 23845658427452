import { IgniteTitleMin } from 'domain/igniteTitle/titles';
import ModifiedAt from './ModifiedAt';
import ModifiedBy from './ModifiedBy';

interface Props {
  title: IgniteTitleMin;
}

const LastUpdate = ({ title }: Props) => (
  <>
    <ModifiedAt time={title.modifiedAt} />
    <ModifiedBy user={title.modifiedBy} />
  </>
);

export default LastUpdate;
