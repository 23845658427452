import { useStringFilterParam } from 'hooks/useStringFilterParam';
import { SearchInput } from './SearchInput';

export const Search = () => {
  const { setParam, paramValue, clearParam } = useStringFilterParam('search');
  return (
    <SearchInput
      paramValue={paramValue}
      onSubmit={setParam}
      onClear={clearParam}
    />
  );
};
