import { Source } from 'domain/igniteTitle/authority';
import { Scheduling } from './scheduling';

const DEFAULT_SCHEDULING: Scheduling = {
  txDate: {
    source: Source.IGNITE,
    value: null,
  },
  furtherInformation: null,
  live: null,
};

export const SchedulingBuilder = (scheduling?: Scheduling): Scheduling => {
  return {
    txDate: scheduling?.txDate ?? DEFAULT_SCHEDULING.txDate,
    furtherInformation:
      scheduling?.furtherInformation ?? DEFAULT_SCHEDULING.furtherInformation,
    live: scheduling?.live ?? DEFAULT_SCHEDULING.live,
  };
};
