import axios from 'axios';
import * as rax from 'retry-axios';
import { handleSuccess } from 'utils/handleSuccess';
import { handleError } from 'utils/handleError';
import { getConfig } from 'utils/config';

const config = getConfig();
const timeout = 30000;

const { BUSINESS_API_BASE_URI } = config;

export const businessesApiClient = axios.create({
  baseURL: BUSINESS_API_BASE_URI,
  timeout,
  headers: {
    Accept: 'application/json',
  },
  withCredentials: true,
  raxConfig: {
    retry: 3,
    noResponseRetries: 2,
  },
});

businessesApiClient.defaults.raxConfig = {
  instance: businessesApiClient,
};

businessesApiClient.interceptors.response.use(handleSuccess);
const retryInterceptor = rax.attach(businessesApiClient);
const errorInterceptor = businessesApiClient.interceptors.response.use(
  undefined,
  handleError
);

export const ejectBusinessesApiClientInterceptors = () => {
  businessesApiClient.interceptors.response.eject(errorInterceptor);
  businessesApiClient.interceptors.response.eject(retryInterceptor);
};
