import { useState } from 'react';

import { CreateNewTitleMenu } from './CreateNewTitleMenu';

enum ModalTypes {
  SeriesModal = 'SeriesModal',
  FilmModal = 'FilmModal',
  OneOffSpecialModal = 'OneOffSpecialModal',
}

export const CreateNewTitle = () => {
  const [openModalType, setOpenModalType] = useState<undefined | ModalTypes>(
    undefined
  );

  const showModal = (modalType: ModalTypes) => {
    setOpenModalType(modalType);
  };

  const closeModal = () => {
    setOpenModalType(undefined);
  };

  return (
    <CreateNewTitleMenu
      openModalType={openModalType}
      showModal={showModal}
      closeModal={closeModal}
    />
  );
};
