import { fetchTitles } from 'api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { IgniteTitleFilterParams } from 'domain/igniteTitle/titles';
import { useLocation } from 'react-router-dom';
import useViewportHeight from './useViewportHeight';

const useDashboardTitles = () => {
  const { pathname } = useLocation();
  const { titlesToDisplayCount } = useViewportHeight();
  const [queryParams]: [Partial<IgniteTitleFilterParams>, any] = useQueryParams(
    {
      producerId: ArrayParam,
      showArchived: StringParam,
      productionStatus: ArrayParam,
      projectStatus: ArrayParam,
      search: StringParam,
      sortBy: StringParam,
      order: StringParam,
      targetPeriod: ArrayParam,
      team: ArrayParam,
    }
  );

  const apiRequest = async (lastId: string) =>
    fetchTitles(
      {
        count: titlesToDisplayCount.toString(),
        lastId,
      },
      queryParams
    );

  return useInfiniteQuery({
    queryKey: ['dashboardResults', queryParams],
    queryFn: ({ pageParam: lastId }) => apiRequest(lastId),
    initialPageParam: '',
    getNextPageParam: (lastResults) => {
      if (lastResults?.length < titlesToDisplayCount) return null;
      if (lastResults?.length) {
        return lastResults?.slice(-1)[0]?.id;
      }

      return undefined;
    },
    enabled: pathname.includes('/dashboard'),
  });
};

export { useDashboardTitles };
