import { useEffect, useMemo, useState } from 'react';
import { useFilterParam } from 'hooks/useFilterParam';
import { Producer } from 'domain/business';
import { useBusinesses } from 'hooks/useBusinesses';

interface Option {
  value: Producer;
  label: string;
  id: string;
  key: string;
}

const mapProducersForAutocomplete = (producers: Producer[]) => {
  return producers.map((producer) => ({
    value: producer,
    label: producer.name,
    id: JSON.stringify(producer),
    key: JSON.stringify(producer),
  }));
};

const useProductionCompanyFilter = () => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly Option[]>([]);
  const { addParamValue, clearParam, paramValues, removeParamValue } =
    useFilterParam('producerId', true);
  const loading = open && options.length === 0;

  const {
    data: businesses = { producers: [], broadcasters: [] },
    error,
    refetch,
    isFetching,
  } = useBusinesses(false);

  const mappedOptions = useMemo(
    () => mapProducersForAutocomplete(businesses.producers),
    [businesses.producers.length]
  );

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async () => {
      if (businesses.producers.length === 0) await refetch();
      else {
        setOptions(mappedOptions);
      }
    })();
  }, [loading, businesses]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const selectedValues = businesses.producers.filter(({ id }) =>
    (paramValues as string[]).includes(id)
  );

  const selectedCompaniesNames = selectedValues.map(({ name }) => name);

  return {
    options,
    selectedValues,
    handleSelect: ({ id }: Producer) => addParamValue(id),
    handleRemove: ({ id }: Producer) => removeParamValue(id),
    handleClear: clearParam,
    error,
    selectedCompaniesNames,
    open,
    setOpen,
    loading: isFetching || loading,
  };
};

export default useProductionCompanyFilter;
