export const isTypeFromValidate = <T>(
  validator: (type: T) => type is T,
  typeInstance: T
) => {
  try {
    return validator(typeInstance);
  } catch {
    return false;
  }
};
