import {
  hasNonEmptyStringProperty,
  isBoolean,
  isNumber,
  isPropertyWithVerify,
  validateNotNull,
} from '../../../guards';

export interface Genre {
  value: number;
  description: string;
  deprecated: boolean;
}

export const validateGenre = (argument: any) => {
  validateNotNull(argument, `Invalid Genre type, object is null`);
  isPropertyWithVerify(
    argument.value,
    isNumber,
    `${argument.value} is not a number`
  );
  isPropertyWithVerify(
    argument.value,
    (p) => (p as any) > 0,
    `value should be greater than 0`
  );
  hasNonEmptyStringProperty(argument, 'genre', 'description');
  isPropertyWithVerify(
    argument.deprecated,
    isBoolean,
    `${argument.deprecated} is not a boolean`
  );

  return true;
};

export interface SubGenre {
  id?: string;
  value: number;
  description: string;
  deprecated: boolean;
}

export const validateSubGenre = (argument: any) => {
  validateNotNull(argument, `Invalid SubGenre type, object is null`);
  isPropertyWithVerify(argument.value, isNumber, `value is not a number`);
  isPropertyWithVerify(
    argument.value,
    (p: number) => p > 0,
    'value should be greater than 0'
  );
  hasNonEmptyStringProperty(argument, 'subGenre', 'description');
  isPropertyWithVerify(
    argument.deprecated,
    isBoolean,
    `${argument.deprecated} is not a boolean`
  );
  return true;
};
