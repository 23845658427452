import { ReactNode } from 'react';

interface Props {
  visible?: boolean;
  children: ReactNode;
}

const ListContent = ({ visible = true, children }: Props) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{visible && children}</>
);

export { ListContent };
