export enum TimeSlots {
  DAYTIME = 'DAYTIME',
  SHOULDERPEAK = 'SHOULDERPEAK',
  PRIMETIME = 'PRIMETIME',
  POSTWATERSHED = 'POSTWATERSHED',
}

export const validateTimeSlotsType = (argument: any) => {
  if (Object.values(TimeSlots).includes(argument)) {
    return true;
  }
  throw new TypeError(
    `Invalid TimeSlots value '${argument}', should be one of 'DAYTIME', 'SHOULDERPEAK', 'PRIMETIME' or 'POSTWATERSHED'`
  );
};
