import { ReactNode } from 'react';
import { BoxProps } from '@mui/material';
import { Flex } from './Flex';

interface Props extends BoxProps {
  children: ReactNode;
}

export const Container = ({ children, ...props }: Props) => {
  return (
    <Flex
      width="1140px"
      ml="auto"
      mr="auto"
      position="relative"
      maxWidth="100%"
      {...props}
    >
      {children}
    </Flex>
  );
};
