import { revokeSession } from 'api';

const voidSession = async () => {
  try {
    await revokeSession();
  } catch (error) {
    console.error('Login Session used to revoke was already expired');
    throw error;
  }
};

export default voidSession;
