import { validateNotNull, hasNonEmptyStringProperty } from 'domain/guards';

export type Country = {
  alpha2: string;
  name: string;
  id?: string; // the autocomplete component requires this as a unique identifier
};

export const validateCountry = (argument: Country) => {
  validateNotNull(argument, `Invalid Country type, object is null`);
  hasNonEmptyStringProperty(argument, 'country', 'alpha2');
  hasNonEmptyStringProperty(argument, 'country', 'name');
  return true;
};
