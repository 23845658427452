import { styled, Chip } from '@mui/material';
import { Flex } from 'components/Layout';
import { IgniteTitleMin } from 'domain/igniteTitle/titles';
import { ProjectSubstatus } from './ProjectSubstatus';

const StyledProjectStatus = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const TitleRowCellContainer = styled('div')`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

interface Props {
  title: IgniteTitleMin;
}

export const ProjectStatus = ({ title }: Props) => {
  return (
    <Flex
      alignItems="center"
      aria-label={`${title.projectStatus.status.name}, ${
        title.projectStatus?.subStatus?.name
          ? title.projectStatus?.subStatus?.name
          : ''
      }`}
    >
      <TitleRowCellContainer>
        {title?.archival ? (
          <Chip label="Archived" color="info" />
        ) : (
          <>
            <StyledProjectStatus>
              {title.projectStatus.status.name}
            </StyledProjectStatus>
            <ProjectSubstatus title={title} />
          </>
        )}
      </TitleRowCellContainer>
    </Flex>
  );
};
