import {
  hasNonEmptyStringProperty,
  isNumber,
  isPropertyWithVerify,
} from '../guards';

export interface ProductionStatus {
  name: string;
  index: number;
}

export const validateProductionStatus = (argument: any) => {
  hasNonEmptyStringProperty(argument, 'ProductionStatus', 'name');
  isPropertyWithVerify(
    argument.index,
    isNumber,
    'Invalid ProductionStatus type, index is not a number'
  );
  return true;
};
