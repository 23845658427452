import { Box, Skeleton, styled } from '@mui/material';

const Header = styled(Skeleton)`
  background-color: var(--secondary-nav-bg-color);
`;

export const TitleDashboardLoading = () => {
  return (
    <Box width="100%" aria-label="Loading...">
      <Header
        width="100%"
        height={100}
        variant="rectangular"
        animation="wave"
      />
    </Box>
  );
};
