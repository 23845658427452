import { Skeleton, styled } from '@mui/material';
import { forwardRef } from 'react';

const StyledTitleRow = styled('tr')`
  display: flex;
  align-items: center;
  max-width: 1110px;
  height: 64px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 10px;
  > * {
    margin: 16px;
  }
`;

interface Props {
  visible?: boolean;
}

const LoadingData = () => (
  <Skeleton width={158} height={16} variant="rectangular" component="td" />
);

const ListLoadingSingle = forwardRef<HTMLTableRowElement, Props>(
  ({ visible = true }, ref) =>
    visible ? (
      <StyledTitleRow ref={ref} aria-label="Loading..." className="loadingRow">
        <LoadingData />
        <LoadingData />
        <LoadingData />
        <LoadingData />
        <LoadingData />
        <LoadingData />
      </StyledTitleRow>
    ) : null
);

export { ListLoadingSingle };
