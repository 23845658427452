import { v4 as uuidv4 } from 'uuid';
import useViewportHeight from 'hooks/useViewportHeight';
import { ListLoadingSingle } from '../ListLoadingSingle';

interface Props {
  visible?: boolean;
}

const ListLoading = ({ visible = true }: Props) => {
  const { titlesToDisplayCount } = useViewportHeight();
  const rowsWithKeys = [...new Array(titlesToDisplayCount)].map(() => uuidv4());
  return visible ? (
    <tbody>
      {rowsWithKeys.map((id) => (
        <ListLoadingSingle key={id} />
      ))}
    </tbody>
  ) : null;
};

export { ListLoading };
