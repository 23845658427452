import { useQueryParam } from 'use-query-params';
import { useEffect } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';

export interface UseStringFilterParam {
  clearParam: () => void;
  setParam: (value: string) => void;
  paramValue: string | undefined;
}

const useStringFilterParam = (filterKey: string): UseStringFilterParam => {
  const [cachedValue, setCachedValue] = useLocalStorage<string>(
    `filter_${filterKey}`
  );
  const [paramValue, setParamValue] = useQueryParam<string | undefined>(
    filterKey
  );

  useEffect(() => {
    if (!paramValue && cachedValue) {
      setParamValue(cachedValue);
    }
  }, [cachedValue, paramValue, setParamValue]);

  const setParam = (value: string) => {
    setParamValue(value);
    setCachedValue(value);
  };

  const clearParam = () => {
    setParamValue(undefined);
    setCachedValue('');
  };

  return {
    clearParam,
    setParam,
    paramValue: paramValue || cachedValue || undefined,
  };
};

export { useStringFilterParam };
