import { styled } from '@mui/material';

import { Flex, Container } from 'components/Layout';
import {
  ArchivedProjectsToggle,
  ProductionStatus,
  ProjectStatus,
  ReviewDate,
  Team,
  ProductionCompany,
  Search,
} from './components';

const StyledSearchAndFilter = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;

  > div:not(:last-child) {
    margin-right: 8px;
  }
`;

const FilterWrapper = styled('section')`
  width: 100%;
`;

const StyledSection = styled('section')`
  background-color: var(--secondary-nav-bg-color);
  padding: 16px;
  min-height: 100px;
`;

const SearchAndFilter = () => (
  <StyledSection>
    <Container sx={{ px: 3 }}>
      <FilterWrapper>
        <Flex justifyContent="space-between" alignItems="center" mb={3}>
          <Search />
          <ArchivedProjectsToggle />
        </Flex>
        <StyledSearchAndFilter>
          <Flex flex={1}>
            <Team />
          </Flex>
          <Flex flex={1}>
            <ProjectStatus />
          </Flex>
          <Flex flex={1}>
            <ProductionStatus />
          </Flex>
          <Flex flex={2}>
            <ProductionCompany />
          </Flex>
          <Flex flex={1}>
            <ReviewDate />
          </Flex>
        </StyledSearchAndFilter>
      </FilterWrapper>
    </Container>
  </StyledSection>
);

export { SearchAndFilter };
