import {
  isNonEmptyString,
  isPropertyWithVerify,
  validateNotNull,
} from 'domain/guards';

export enum DateUnitTypes {
  'DATE' = 'DATE',
  'MONTH' = 'MONTH',
  'YEAR' = 'YEAR',
  'QUARTER' = 'QUARTER',
}

export type DateWithUnitType = {
  unit: DateUnitTypes;
  date: string;
};

export type StartDateRange = {
  start: DateWithUnitType;
  end?: Nullable<DateWithUnitType>;
};

export type EndDateRange = {
  start?: Nullable<DateWithUnitType>;
  end: DateWithUnitType;
};

export type ProductionDateRange = StartDateRange | EndDateRange;

export const validateDateWithUnitType = (argument: DateWithUnitType) => {
  if (!['DATE', 'MONTH', 'YEAR', 'QUARTER'].includes(argument.unit)) {
    throw new TypeError(`${argument} is not a DateWithUnitType`);
  }

  isPropertyWithVerify(
    argument.date,
    isNonEmptyString,
    `Invalid DateWithUnitType.date type, should be nonEmptyString`
  );

  return true;
};

export const validateStartDateRangeWithUnitType = (
  argument: Nullable<StartDateRange> | Nullable<ProductionDateRange>
) => {
  if (argument === null) return true;

  validateNotNull(
    argument.start,
    'Invalid StartDateRange, start value is null'
  );

  if (argument.start) {
    if (!['DATE', 'MONTH', 'YEAR', 'QUARTER'].includes(argument.start.unit)) {
      throw new TypeError(`${argument.start.unit} is not a DateWithUnitType`);
    }

    isPropertyWithVerify(
      argument.start.date,
      isNonEmptyString,
      `Invalid DateWithUnitType.date type, should be nonEmptyString`
    );
  }
  return true;
};

export const validateEndDateRangeWithUnitType = (argument: EndDateRange) => {
  validateNotNull(argument.end, 'Invalid StartDateRange, end value is null');
  if (!['DATE', 'MONTH', 'YEAR', 'QUARTER'].includes(argument.end.unit)) {
    throw new TypeError(`${argument.end.unit} is not a DateWithUnitType`);
  }

  isPropertyWithVerify(
    argument.end.date,
    isNonEmptyString,
    `Invalid DateWithUnitType.end.date type, should be nonEmptyString`
  );

  return true;
};
