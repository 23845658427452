/* eslint-disable no-console */
import { createContext, useContext, useEffect, useReducer } from 'react';

import { Security } from '@okta/okta-react';
import {
  OktaAuth,
  OktaAuthOptions,
  toRelativeUrl,
  // @ts-expect-error: Using core bundle until this is fixed https://github.com/okta/okta-auth-js/issues/1484
} from '@okta/okta-auth-js/core';

import { OktaDomains } from 'domain/oktaDomains';
import { getOktaConfig } from 'utils/config';
import getSessionCookie from 'utils/getSessionCookie';
import getUserInfo from 'utils/getUserInfo';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserProfile } from 'domain/user';

const oktaConfig = getOktaConfig() as OktaAuthOptions;
const oktaAuth = new OktaAuth(oktaConfig);

interface LoginSuccessType {
  type: 'LOGIN_SUCCESS';
  payload: {
    sessionId: string;
    userProfile: UserProfile;
    oktaDomain: OktaDomains;
  };
}

interface LoginFailure {
  type: 'LOGIN_FAILURE';
  payload: AxiosError<any>;
}
interface Logout {
  type: 'LOGOUT';
}

export type ActionType = LoginSuccessType | LoginFailure | Logout;

type DispatchType = (action: ActionType) => void;

interface State {
  isLoggedIn: boolean;
  sessionId?: string;
  oktaDomain?: OktaDomains;
  userProfile?: UserProfile;
}

interface AuthProviderProps {
  children: React.ReactNode;
  initialState?: State;
}
const initialAuthState = {
  isLoggedIn: false,
  oktaDomain: undefined,
  userProfile: undefined,
};

export const AuthStateContext = createContext<State>(initialAuthState);
export const AuthDispatchContext = createContext<DispatchType | undefined>(
  undefined
);

export const fetchUserSession = async (code: any, dispatch: any) => {
  try {
    await getSessionCookie(code);
    const userProfile = await getUserInfo();

    dispatch({
      type: 'LOGIN_SUCCESS',
      payload: {
        userProfile,
        oktaDomain: userProfile.domain,
      },
    });
  } catch (error) {
    dispatch({ type: 'LOGIN_FAILURE', payload: { error } });
  }
};

const authReducer = (state: State, action: any) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS': {
      return {
        isLoggedIn: true,
        sessionId: action.payload.sessionId,
        userProfile: action.payload.userProfile,
        oktaDomain: action.payload.oktaDomain,
      };
    }
    case 'LOGIN_FAILURE': {
      console.error(action.payload);
      return { ...state, isLoggedIn: false };
    }
    case 'LOGOUT': {
      localStorage.clear();
      console.info('Logged out of Ignite');
      return { ...state, isLoggedIn: false };
    }
    default:
      return state;
  }
};

const AuthProvider = ({
  children,
  initialState = initialAuthState,
}: AuthProviderProps) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri = '/') => {
    navigate(toRelativeUrl(originalUri, window.location.origin), {
      replace: true,
    });
  };
  useEffect(() => {
    return () => {
      oktaAuth.options.restoreOriginalUri = undefined;
    };
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AuthStateContext.Provider value={state}>
        <AuthDispatchContext.Provider value={dispatch}>
          {children}
        </AuthDispatchContext.Provider>
      </AuthStateContext.Provider>
    </Security>
  );
};

const useAuthState = () => {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within an AuthProvider');
  }
  return context;
};

const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuthState, useAuthDispatch };
