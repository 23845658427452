import { RefObject } from 'react';
import { Clear } from '@mui/icons-material';
import { IconButton } from '@mui/material';

interface Props {
  isVisible: boolean;
  inputRef: RefObject<HTMLInputElement>;
  onClear: () => void;
}

export const ClearIcon = ({ isVisible, inputRef, onClear }: Props) => {
  if (!isVisible) {
    return null;
  }
  return (
    <IconButton
      aria-label="clear"
      data-testid="clear-search-icon"
      onClick={() => {
        onClear();
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
      size="small"
    >
      <Clear fontSize="small" />
    </IconButton>
  );
};
