import {
  hasNonEmptyStringProperty,
  isPropertyWithVerify,
  validateNotNull,
} from '../guards';
import { UserInfo, validateUserInfo } from './userInfo';

export enum NoteTypes {
  General = 'General',
  Finance = 'Finance',
  ConditionOfApproval = 'ConditionOfApproval',
}

export const validateNoteType = (argument: string) => {
  validateNotNull(argument, `Invalid NoteType type, NoteType is null`);
  if (argument.trim() === '') {
    throw new TypeError(
      `Invalid NoteType type, '${argument}' is not a non empty string`
    );
  }
  return true;
};

export interface NewNote {
  noteType: string;
  text: string;
}

export interface UpdateNote {
  noteType: string;
  id: string;
  text: string;
}

export interface DeleteNote {
  noteType: string;
  id: string;
}

export interface Note {
  noteType: string;
  id: string;
  text: string;
  createdBy: UserInfo;
  createdAt: string;
  modifiedBy: UserInfo;
  modifiedAt: string;
}

export const validateNote = (argument: Note) => {
  validateNotNull(argument, `Invalid Note type, object is null`);
  isPropertyWithVerify(
    argument.noteType,
    validateNoteType,
    'Invalid Note type, noteType is not a NoteType'
  );
  hasNonEmptyStringProperty(argument, 'note', 'text');
  isPropertyWithVerify(
    argument.createdBy,
    validateUserInfo('note', 'createdBy'),
    'Invalid Note type, createdBy is not a UserInfo'
  );
  hasNonEmptyStringProperty(argument, 'note', 'createdAt');
  isPropertyWithVerify(
    argument.modifiedBy,
    validateUserInfo('note', 'modifiedBy'),
    'Invalid Note type, modifiedBy is not a UserInfo'
  );
  hasNonEmptyStringProperty(argument, 'note', 'modifiedAt');

  return true;
};
