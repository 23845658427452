import { styled } from '@mui/material';

const StyledEnvironment = styled('i')`
  color: white;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 5px;
`;
interface Props {
  environment: string;
}

export const Environment = ({ environment }: Props) => (
  <StyledEnvironment aria-hidden>{environment}</StyledEnvironment>
);
