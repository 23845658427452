import { writeStorage } from '@rehooks/local-storage';
import { useUserProfile } from 'hooks/useUserProfile';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  children: JSX.Element;
}

export const RequireAuth = ({ children }: Props) => {
  const location = useLocation();
  useEffect(() => {
    if (location?.pathname) writeStorage('redirectTo', location.pathname);
  }, [location]);
  const { isSuccess } = useUserProfile();
  return isSuccess ? children : null;
};
