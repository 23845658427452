import api from 'api';

const getSessionCookie = async (oneTimeCode: string) => {
  const session = await api.createSession({
    oneTimeCode,
  });
  return session;
};

export default getSessionCookie;
