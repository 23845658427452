import { useNavigate } from 'react-router-dom';

import { IgniteTitleMin } from 'domain/igniteTitle/titles';
import { styled } from '@mui/material';
import ProjectName from './ProjectName';
import { Producers } from './Producers';
import TxDate from './TxDate';
import { ProjectStatus } from './ProjectStatus';
import TargetDate from './TargetDate';
import LastUpdate from './LastUpdate';

const StyledTitleRow = styled('tr')`
  display: flex;
  align-items: center;
  max-width: 1110px;
  height: 64px;
  color: #22252f;
  position: relative;
  border-radius: 8px;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);
  background-color: #fff;
`;

interface StyleProps {
  flexWidth: number;
}
const StyledColumn = styled('td')<StyleProps>`
  ${(props) =>
    props.flexWidth && `flex: 0 0 calc(100% / (12 / ${props.flexWidth}))`};
  overflow: auto;
  padding: 0 16px;
`;

const ScreenReaderOnlyColumn = styled('td')`
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  padding: 16px;
  right: 0;
`;

interface Props {
  title: IgniteTitleMin;
}

export const TitleRow = ({ title }: Props) => {
  const navigate = useNavigate();
  const navigateToTitlePage = () => navigate(`/titles/${title.id}/details`);

  // We won't need this once we change the list from a table to something else
  const handleTitleRowClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    if (event.metaKey || event.ctrlKey || event.button === 1) {
      // If Ctrl click or middle click is pressed open a new tab
      window.open(
        `${window.location.origin}/titles/${title?.id}/details`,
        '_blank'
      );
    } else {
      navigateToTitlePage();
    }
  };

  const handleTitleRowKeyDown = (
    event: React.KeyboardEvent<HTMLTableRowElement>
  ) => {
    if (event.key === 'Enter') {
      navigateToTitlePage();
    }
  };

  return (
    <StyledTitleRow
      tabIndex={0}
      onMouseUp={handleTitleRowClick}
      onKeyDown={handleTitleRowKeyDown}
    >
      <StyledColumn flexWidth={3}>
        <ProjectName title={title} />
      </StyledColumn>
      <StyledColumn flexWidth={2}>
        <Producers title={title} />
      </StyledColumn>
      <StyledColumn flexWidth={2}>
        <ProjectStatus title={title} />
      </StyledColumn>
      <StyledColumn flexWidth={2}>
        <TxDate title={title} />
      </StyledColumn>
      <StyledColumn flexWidth={1}>
        <TargetDate title={title} />
      </StyledColumn>
      <StyledColumn flexWidth={2}>
        <LastUpdate title={title} />
      </StyledColumn>
      <ScreenReaderOnlyColumn>
        <a
          aria-label="View title details"
          href={`/titles/${title?.id}`}
          tabIndex={-1}
        >
          View title details
        </a>
      </ScreenReaderOnlyColumn>
    </StyledTitleRow>
  );
};
