import { AmortisationProfile } from 'domain/datasets';
import {
  isPropertyWithVerify,
  isArrayPropertyWithVerify,
  isBoolean,
  isNonEmptyString,
  validateNotNull,
} from '../../../guards';
import { MonetaryAmount, validateMonetaryAmount } from './monetaryAmount';

export interface PerHourAndTotal extends TotalOnly {
  perHour: Nullable<MonetaryAmount>;
  total: Nullable<MonetaryAmount>;
}

export const validatePerHourAndTotal = (argument: Record<string, any>) => {
  validateNotNull(argument, `Invalid PerHourAndTotal type, object is null`);

  if (argument.perHour != null) {
    isPropertyWithVerify(
      argument.perHour,
      validateMonetaryAmount,
      `Invalid PerHourAndTotal.perHour type, should be MonetaryAmount`
    );
  }

  if (argument.total != null) {
    isPropertyWithVerify(
      argument.total,
      validateMonetaryAmount,
      `Invalid PerHourAndTotal.total type, should be MonetaryAmount`
    );
  }
  return true;
};

export interface TotalOnly {
  total: Nullable<MonetaryAmount>;
}

export const validateTotalOnly = (argument: Record<string, any>) => {
  validateNotNull(argument, `Invalid TotalOnly type, object is null`);

  if (argument.total != null) {
    isPropertyWithVerify(
      argument.total,
      validateMonetaryAmount,
      'Invalid TotalOnly.total, should be MonetaryAmount'
    );
  }
  return true;
};

export interface PerEpisodeAndSeries {
  perHour: Nullable<MonetaryAmount>;
  perEpisode: Nullable<MonetaryAmount>;
  perSeries: Nullable<MonetaryAmount>;
}

export const validatePerEpisodeAndSeries = (argument: Record<string, any>) => {
  validateNotNull(argument, `Invalid PerEpisodeAndSeries type, object is null`);

  if (argument.perEpisode != null) {
    isPropertyWithVerify(
      argument.perEpisode,
      validateMonetaryAmount,
      `Invalid PerEpisodeAndSeries.perEpisode, should be MonetaryAmount`
    );
  }

  if (argument.perSeries != null) {
    isPropertyWithVerify(
      argument.perSeries,
      validateMonetaryAmount,
      `Invalid PerEpisodeAndSeries.perSeries, should be MonetaryAmount`
    );
  }
  return true;
};

export interface NamedPerEpisodeAndSeries extends PerEpisodeAndSeries {
  name: string;
}

export const validateNamedPerEpisodeAndSeries = (
  argument: Record<string, any>
) => {
  validateNotNull(
    argument,
    `Invalid NamedPerEpisodeAndSeries type, object is null`
  );

  isPropertyWithVerify(
    argument.name,
    isNonEmptyString,
    `Invalid NamedPerEpisodeAndSeries.name, should be string`
  );

  validatePerEpisodeAndSeries(argument);
  return true;
};

export interface FinanceWithPerHourAndTotal {
  budget: PerHourAndTotal;
  deficit: PerHourAndTotal;
  commissioningLicenseFee: PerHourAndTotal;
}

export interface FinanceWithTotalOnly {
  taxCredit: TotalOnly;
  coProFinancing: TotalOnly;
}

export interface GDFinance
  extends FinanceWithPerHourAndTotal,
    FinanceWithTotalOnly {
  affordableWithinBusinessPlan?: boolean;
  rationale?: string;
}

export const validateGDFinance = (argument: Record<string, any>) => {
  validateNotNull(argument, `Invalid Finance type, object is null`);

  isPropertyWithVerify(
    argument.budget,
    validatePerHourAndTotal,
    `Invalid Finance.budget, should be PerHourAndTotal`
  );

  isPropertyWithVerify(
    argument.deficit,
    validatePerHourAndTotal,
    `Invalid Finance.deficit, should be PerHourAndTotal`
  );

  isPropertyWithVerify(
    argument.commissioningLicenseFee,
    validatePerHourAndTotal,
    `Invalid Finance.commissioningLicenseFee, should be PerHourAndTotal`
  );

  isPropertyWithVerify(
    argument.taxCredit,
    validateTotalOnly,
    `Invalid Finance.taxCredit, should be TotalOnly`
  );

  isPropertyWithVerify(
    argument.coProFinancing,
    validateTotalOnly,
    `Invalid Finance.coProFinancing, should be TotalOnly`
  );

  if (argument.affordableWithinBusinessPlan != null) {
    isPropertyWithVerify(
      argument.affordableWithinBusinessPlan,
      isBoolean,
      'Invalid Finance.affordableWithinBusinessPlan, should be boolean'
    );
  }

  if (argument.rationale != null) {
    isPropertyWithVerify(
      argument.rationale,
      isNonEmptyString,
      'Invalid Finance.rationale, should be a non-empty string'
    );
  }

  return true;
};

export interface UKFinancePerEpisodeAndSeries {
  licenseFee: NamedPerEpisodeAndSeries[];
  breakage: NamedPerEpisodeAndSeries[];
  contingencies: NamedPerEpisodeAndSeries[];
  developmentFee: PerEpisodeAndSeries;
  directorsUkFee: PerEpisodeAndSeries;
  screenSkillsFee: PerEpisodeAndSeries;
  totalCost: PerEpisodeAndSeries;
}

export interface UKFinance extends UKFinancePerEpisodeAndSeries {
  bankFinancingIpaRequired: Nullable<boolean>;
  previousSeriesLicenceFee: Nullable<MonetaryAmount>;
  comparableTitles: Nullable<string>;
  amortisationProfile: Nullable<AmortisationProfile>;
}

export const validateUKFinance = (argument: Record<string, any>) => {
  validateNotNull(argument, `Invalid Finance type, object is null`);

  isArrayPropertyWithVerify(
    argument.licenseFee,
    validateNamedPerEpisodeAndSeries,
    'Invalid Finance.licenseFee, should be NamedPerEpisodeAndSeries Array'
  );

  isArrayPropertyWithVerify(
    argument.breakage,
    validateNamedPerEpisodeAndSeries,
    'Invalid Finance.breakage, should be NamedPerEpisodeAndSeries Array'
  );

  isArrayPropertyWithVerify(
    argument.contingencies,
    validateNamedPerEpisodeAndSeries,
    'Invalid Finance.contingencies, should be NamedPerEpisodeAndSeries Array'
  );

  isPropertyWithVerify(
    argument.developmentFee,
    validatePerEpisodeAndSeries,
    'Invalid Finance.developmentFee, should be PerEpisodeAndSeries'
  );

  isPropertyWithVerify(
    argument.directorsUkFee,
    validatePerEpisodeAndSeries,
    'Invalid Finance.directorsUkFee, should be PerEpisodeAndSeries'
  );

  isPropertyWithVerify(
    argument.screenSkillsFee,
    validatePerEpisodeAndSeries,
    'Invalid Finance.screenSkillsFee, should be PerEpisodeAndSeries'
  );

  isPropertyWithVerify(
    argument.totalCost,
    validatePerEpisodeAndSeries,
    'Invalid Finance.totalCost, should be PerEpisodeAndSeries'
  );

  if (argument.bankFinancingIpaRequired != null) {
    isPropertyWithVerify(
      argument.bankFinancingIpaRequired,
      isBoolean,
      'Invalid Finance.bankFinancingIpaRequired, should be a boolean'
    );
  }

  if (argument.previousSeriesLicenceFee != null) {
    isPropertyWithVerify(
      argument.previousSeriesLicenceFee,
      validateMonetaryAmount,
      'Invalid Finance.previousSeriesLicenceFee, should be a MonetaryAmount'
    );
  }

  if (argument.comparableTitles != null) {
    isPropertyWithVerify(
      argument.comparableTitles,
      isNonEmptyString,
      'Invalid Finance.comparableTitles, should be a non empty string'
    );
  }

  return true;
};

export interface Finance extends GDFinance, UKFinance {}

export const validateFinance = (argument: Record<string, any>) => {
  validateGDFinance(argument);
  validateUKFinance(argument);
  return true;
};
