import {
  isNonEmptyString,
  isPropertyWithVerify,
  validateNotNull,
} from 'domain/guards';
import { Authority, validateAuthorityOf } from 'domain/igniteTitle/authority';
import { StartDateRange, validateStartDateRangeWithUnitType } from '../date';

export interface Scheduling {
  txDate: Authority<Nullable<StartDateRange>>;
  furtherInformation: Nullable<string>;
  live: Nullable<boolean>;
}

export const validateScheduling = (argument: Scheduling) => {
  validateNotNull(argument, `Invalid Scheduling type, object is null`);

  if (argument.txDate != null) {
    isPropertyWithVerify(
      argument.txDate,
      validateAuthorityOf('txDate', validateStartDateRangeWithUnitType),
      `Invalid Scheduling.txDate type, should be StartDateRange`
    );
  }

  if (argument.furtherInformation != null) {
    isPropertyWithVerify(
      argument.furtherInformation,
      isNonEmptyString,
      `Invalid Scheduling.furtherInformation type, should be NonEmptyString`
    );
  }

  if (argument.live != null) {
    isPropertyWithVerify(
      argument.live,
      (val) => typeof val === 'boolean',
      `Invalid Scheduling.live type, should be boolean`
    );
  }

  return true;
};
