import { validateNotNull, hasNonEmptyStringProperty } from './guards';

export type BusinessData = {
  id: string;
  name: string;
  parisId?: string;
  isProducer: boolean;
  isBroadcaster: boolean;
  isDistributor: boolean;
};

const hasBusinessFields = (type: any, argument: any) => {
  validateNotNull(argument, `Invalid ${type} type, object is null`);
  hasNonEmptyStringProperty(argument, type, 'id');
  hasNonEmptyStringProperty(argument, type, 'name');
};

export type Business = {
  id: string;
  name: string;
  parisId?: string;
};

export const validateBusiness = (argument: any) => {
  hasBusinessFields('business', argument);
  return true;
};

export type Producer = {
  id: string;
  name: string;
  parisId?: string;
};

export const validateProducer = (argument: any) => {
  hasBusinessFields('producer', argument);
  return true;
};

export type Broadcaster = {
  id: string;
  name: string;
  parisId?: string;
  destinations?: string[];
};

export const validateBroadcaster = (argument: any) => {
  hasBusinessFields('broadcaster', argument);
  return true;
};

export type Distributor = {
  id: string;
  name: string;
  parisId?: string;
};

export const validateDistributor = (argument: any) => {
  hasBusinessFields('distributor', argument);
  return true;
};

export type Businesses = {
  producers: Producer[];
  broadcasters: Broadcaster[];
};
