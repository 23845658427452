import { useLocalStorage } from '@rehooks/local-storage';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

export enum SortBy {
  ProjectName = 'projectname',
  ModifiedAt = 'modifiedat',
  TargetDate = 'targetDate',
  TxDate = 'txdate',
}

export enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

const useSort = () => {
  const { pathname } = useLocation();
  const [cachedSortOrder, setCachedSortOrder] =
    useLocalStorage('filter_sort_order');
  const [cachedSortBy, setCachedSortBy] = useLocalStorage('filter_sort_by');

  const [qs, setQs] = useSearchParams();
  const queryParams = Object.fromEntries(qs.entries());
  const setQueryParams = (params: Record<string, string>) => {
    setQs({ ...queryParams, ...params });
  };

  useEffect(() => {
    if (
      (!queryParams.sortBy || !queryParams.order) &&
      pathname.includes('/dashboard')
    ) {
      setQueryParams({
        ...Object.fromEntries(qs.entries()),
        order: cachedSortOrder || SortOrder.Ascending,
        sortBy: cachedSortBy || SortBy.ProjectName,
      });
    }
  }, []);

  const setSort = (sortValues: { sortBy: SortBy; order: SortOrder }) => {
    setCachedSortOrder(sortValues.order);
    setCachedSortBy(sortValues.sortBy);
    setQueryParams({
      order: sortValues.order,
      sortBy: sortValues.sortBy,
    });
  };

  return {
    sortBy: queryParams?.sortBy,
    order: queryParams?.order,
    setSort,
  };
};

export default useSort;
