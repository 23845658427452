import { styled } from '@mui/material';

import { Flex } from 'components/Layout';
import { IgniteTitleMin } from 'domain/igniteTitle/titles';
import Tooltip from '@mui/material/Tooltip';
import { textTruncate } from '../Styles';

interface Props {
  title: IgniteTitleMin;
}

const StyledProducerLineItem = styled('div')`
  ${textTruncate}
  max-width: 150px;
`;

const Producers = ({ title }: Props) => {
  const producers = [...new Set(title.producers)].slice(0, 2);

  return (
    <Flex flexDirection="column" alignItems="start">
      {producers.map((name) => (
        <Tooltip id="producers-tooltip" placement="top" title={name} key={name}>
          <StyledProducerLineItem>{name}</StyledProducerLineItem>
        </Tooltip>
      ))}
    </Flex>
  );
};

export { Producers };
