import { Box, BoxProps } from '@mui/material';
import { forwardRef } from 'react';

interface BoxPropsWithComponent extends Omit<BoxProps, 'component'> {
  component?: React.ElementType;
}
export const Flex = forwardRef((props: BoxPropsWithComponent, ref) => {
  const { sx, ...restProps } = props;
  return <Box {...restProps} sx={{ ...sx, display: 'flex' }} ref={ref} />;
});
