import { useDatasets } from 'hooks/useDatasets';
import { UIFilter } from '../UIFilter';

export const ProductionStatus = () => {
  const { datasets } = useDatasets();

  const options =
    datasets?.productionStatuses.map(({ name }) => ({
      value: name,
      name,
    })) ?? [];

  return (
    <UIFilter
      filterKey="productionStatus"
      filterLabel="Production Status"
      options={options}
    />
  );
};
