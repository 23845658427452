import { Refresh } from '@mui/icons-material';
import { Alert, Snackbar, styled } from '@mui/material';
import { LinkButton } from 'components/LinkButton';
import { useIgniteVersionChecker } from '../hooks';

const StyledRefresh = styled(Refresh)(({ theme }) => ({
  color: theme.palette.white,
  marginLeft: '8px',
}));

const StyledButton = styled(LinkButton)(({ theme }) => ({
  color: theme.palette.white,
  fontSize: '13px',
  margin: '0 8px 0 16px',
}));

const StyledAlert = styled(Alert)({
  '.MuiAlert-action': {
    padding: 0,
    alignItems: 'center',
  },
});

export const IgniteVersionSnackbar = () => {
  const { hasVersionChanged } = useIgniteVersionChecker();

  if (!hasVersionChanged) return null;

  return (
    <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <StyledAlert
        severity="error"
        variant="filled"
        role="alert"
        aria-live="assertive"
        aria-label="Ignite version has changed, please refresh to make edits"
        action={
          <StyledButton
            onClick={() => window.location.reload()}
            aria-label="refresh the page"
          >
            Refresh
            <StyledRefresh aria-label="refresh connection" />
          </StyledButton>
        }
      >
        You're running an old version of Ignite, please refresh to make edits
      </StyledAlert>
    </Snackbar>
  );
};
