import { validateNotNull, hasNonEmptyStringProperty } from 'domain/guards';

export type Language = {
  alpha3: string;
  name: string;
  id?: string; // the autocomplete component requires this as a unique identifier
};

export const validateLanguage = (argument: Language) => {
  validateNotNull(argument, `Invalid Language type, object is null`);
  hasNonEmptyStringProperty(argument, 'language', 'alpha3');
  hasNonEmptyStringProperty(argument, 'language', 'name');
  return true;
};
