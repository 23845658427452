import {
  Button,
  Dialog,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import TvIcon from '@mui/icons-material/Tv';
import MovieIcon from '@mui/icons-material/Movie';
import StarIcon from '@mui/icons-material/Star';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactNode, Suspense, lazy, useState } from 'react';

const CreateSeries = lazy(() => import('features/CreateTitle/CreateSeries'));
const CreateSpecial = lazy(() => import('features/CreateTitle/CreateSpecial'));

enum ModalTypes {
  SeriesModal = 'SeriesModal',
  OneOffSpecialModal = 'OneOffSpecialModal',
  FilmModal = 'FilmModal',
}

const modalRenderer = (
  modalType?: ModalTypes,
  closeModal: () => void = () => null
) => {
  if (!modalType) {
    return null;
  }
  const modal = {
    SeriesModal: <CreateSeries onClose={closeModal} />,
    OneOffSpecialModal: <CreateSpecial onClose={closeModal} />,
    FilmModal: null,
  }[modalType];
  return <Suspense fallback={<Dialog open />}>{modal}</Suspense>;
};

interface Props {
  openModalType?: ModalTypes;
  showModal: (modalType: ModalTypes) => void;
  closeModal: () => void;
}

export const CreateNewTitleMenu = ({
  openModalType,
  showModal,
  closeModal,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="create-new-project-menu"
        aria-controls={open ? 'new-project-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        color="inherit"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Create New Project
      </Button>
      <Menu
        id="new-project-menu"
        MenuListProps={{
          'aria-labelledby': 'create-new-project-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <CustomMenuItem
          autoFocus
          showModal={showModal}
          handleClose={handleClose}
          modalType={ModalTypes.SeriesModal}
        >
          Series
        </CustomMenuItem>

        <Tooltip title="Under construction 🚧" placement="left" arrow>
          <span>
            <CustomMenuItem
              showModal={showModal}
              handleClose={handleClose}
              modalType={ModalTypes.FilmModal}
              disabled
            >
              Film
            </CustomMenuItem>
          </span>
        </Tooltip>

        <CustomMenuItem
          showModal={showModal}
          handleClose={handleClose}
          modalType={ModalTypes.OneOffSpecialModal}
        >
          One-off Special
        </CustomMenuItem>
      </Menu>

      {modalRenderer(openModalType, closeModal)}
    </>
  );
};

const Icon = ({ modalType }: { modalType: ModalTypes }) => {
  switch (modalType) {
    case ModalTypes.SeriesModal:
      return <TvIcon fontSize="small" />;
    case ModalTypes.OneOffSpecialModal:
      return <StarIcon fontSize="small" />;
    case ModalTypes.FilmModal:
      return <MovieIcon fontSize="small" />;
    default:
      return null;
  }
};

interface MenuItemProps {
  autoFocus?: boolean;
  showModal: (modalType: ModalTypes) => void;
  handleClose: () => void;
  children: ReactNode;
  modalType: ModalTypes;
  disabled?: boolean;
}

const CustomMenuItem = ({
  autoFocus,
  showModal,
  handleClose,
  children,
  modalType,
  disabled = false,
}: MenuItemProps) => {
  return (
    <MenuItem
      autoFocus={autoFocus}
      onClick={() => {
        showModal(modalType);
        handleClose();
      }}
      disabled={disabled}
    >
      <ListItemIcon>
        <Icon modalType={modalType} />
      </ListItemIcon>
      <ListItemText>{children}</ListItemText>
    </MenuItem>
  );
};
