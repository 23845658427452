import {
  isArrayOfWithVerify,
  isArrayPropertyWithVerify,
  isNonEmptyString,
  isPropertyWithVerify,
  validateNotNull,
  validateOptional,
} from '../../../guards';
import { TitleType, validateTitleType } from './titleType/titleType';
import { Genre, SubGenre, validateGenre, validateSubGenre } from './genre';
import { TimeSlots, validateTimeSlotsType } from './timeslots';
import { Authority, validateAuthorityOf } from '../../authority';
import { ProductionDateRange, validateDateWithUnitType } from '../date';

export interface Catalogue {
  titleType: TitleType;
  genre: Authority<Nullable<Genre>>;
  subGenres: Authority<SubGenre[]>;
  timeSlots?: TimeSlots[];
  narrative?: string;
  talent?: string;
  productionYear: Authority<Nullable<ProductionDateRange>>;
}

export const CatalogueBuilder = (
  titleType: TitleType,
  genre: Authority<Nullable<Genre>>,
  subGenres: Authority<SubGenre[]>,
  productionYear: Authority<Nullable<ProductionDateRange>>,
  timeSlots?: TimeSlots[],
  narrative?: string,
  talent?: string
) => ({
  titleType,
  genre,
  subGenres,
  productionYear,
  timeSlots,
  narrative,
  talent,
});

export const validateCatalogue = (argument: Record<string, any>) => {
  validateNotNull(argument, `Invalid Catalogue type, object is null`);
  isPropertyWithVerify(
    argument.titleType,
    validateTitleType,
    'Invalid Catalogue type, titleType is not a TitleType'
  );
  isPropertyWithVerify(
    argument.genre,
    validateAuthorityOf('genre', validateOptional(validateGenre)),
    'Invalid Catalogue type, genre is not a Genre'
  );

  if (argument.subGenres != null) {
    isPropertyWithVerify(
      argument.subGenres,
      validateAuthorityOf('subGenres', (arg) =>
        isArrayOfWithVerify(arg as any, 'subGenre', validateSubGenre)
      ),
      'Invalid Catalogue type, subGenres is not a SubGenre[]'
    );
  }

  if (argument.timeSlots != null) {
    isArrayPropertyWithVerify(
      argument.timeSlots,
      validateTimeSlotsType,
      'Invalid Catalogue type, timeSlots is not a TimeSlots[]'
    );
  }

  if (argument.narrative != null) {
    isPropertyWithVerify(
      argument.narrative,
      isNonEmptyString,
      'Invalid Catalogue type, narrative is not a string'
    );
  }

  if (argument.talent != null) {
    isPropertyWithVerify(
      argument.talent,
      isNonEmptyString,
      'Invalid Catalogue type, talent is not a string'
    );
  }

  if (argument.productionYear?.start != null) {
    validateDateWithUnitType(argument.productionYear.start);
  }
  if (argument.productionYear?.end != null) {
    validateDateWithUnitType(argument.productionYear.end);
  }
  return true;
};
