import Tooltip from '@mui/material/Tooltip';
import { formatDateToRelativeText } from 'utils/date';

interface ModifiedAtProps {
  time: string;
}

const ModifiedAt = ({ time }: ModifiedAtProps) => {
  const { relativeFromNow, formated } = formatDateToRelativeText(time);

  return (
    <Tooltip
      title={formated}
      placement="top"
      arrow
      aria-label={relativeFromNow}
    >
      <span>{relativeFromNow}</span>
    </Tooltip>
  );
};

export default ModifiedAt;
