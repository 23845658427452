import { useDatasets } from 'hooks/useDatasets';
import { UIFilter } from '../UIFilter';

export const ProjectStatus = () => {
  const { datasets } = useDatasets();
  const options =
    datasets?.projectStatuses
      .sort((a, b) => a.status.index - b.status.index)
      .map(({ status, substatuses }) => {
        const statusName = status.name;
        return {
          name: statusName,
          value: substatuses.length
            ? substatuses.map(({ name }) => name)
            : statusName,
        };
      }) ?? [];
  return (
    <UIFilter
      filterKey="projectStatus"
      filterLabel="Project Status"
      options={options}
    />
  );
};
