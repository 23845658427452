import {
  isArrayPropertyWithVerify,
  isNumber,
  isPropertyWithVerify,
  validateNotNull,
} from './guards';
import { IgniteTitleMin, isIgniteTitleMin } from './igniteTitle/titles';

export interface SearchResponse {
  totalHits: number;
  offset: number;
  limit: number;
  results: IgniteTitleMin[];
}

export const validateSearchResponse = (argument: SearchResponse) => {
  validateNotNull(argument, `Invalid SearchResponse type, object is null`);

  isPropertyWithVerify(
    argument.totalHits,
    isNumber,
    `Invalid SearchResponse.totalHits type, should be number`
  );

  isPropertyWithVerify(
    argument.offset,
    isNumber,
    `Invalid SearchResponse.offset type, should be number`
  );

  isPropertyWithVerify(
    argument.limit,
    isNumber,
    `Invalid SearchResponse.limit type, should be number`
  );

  isArrayPropertyWithVerify(
    argument.results,
    isIgniteTitleMin,
    `Invalid SearchResponse.results type, should be Array of IgniteTitleMin`
  );
  return true;
};
