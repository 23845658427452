import { fetchUserInfo } from 'api';
import { useQuery } from '@tanstack/react-query';
import { UserProfile } from 'domain/user';

export const useUserProfile = () => {
  return useQuery<UserProfile>({
    queryKey: ['userInfo'],
    queryFn: fetchUserInfo,
  });
};
