import {
  Autocomplete,
  Chip,
  CircularProgress,
  TextField,
  styled,
} from '@mui/material';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import useProductionCompanyFilter from './hooks/useProductionCompanyFilter';

const StyledFilterButton = styled(TextField)`
  & .MuiFilledInput-root {
    background-color: white !important;
    border-radius: 4px;
  }
`;

export const ProductionCompany = () => {
  const {
    options,
    selectedValues,
    handleSelect,
    handleRemove,
    handleClear,
    error,
    selectedCompaniesNames,
    open,
    setOpen,
    loading,
  } = useProductionCompanyFilter();
  const { logDashboardFilterEvent } = useGoogleAnalytics();

  return (
    <Autocomplete
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      open={open}
      fullWidth
      id="btn-filter-ProductionCompany"
      options={options}
      multiple
      onChange={(e, value, reason, detail) => {
        if (reason === 'clear') handleClear();
        if (!detail?.option.value) return;
        if (reason === 'selectOption') handleSelect(detail?.option.value);
        if (reason === 'removeOption') handleRemove(detail?.option.value);
        logDashboardFilterEvent(
          'productionCompany',
          [...selectedCompaniesNames, detail?.option.value.name].join(', ')
        );
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.label}
            {...getTagProps({ index })}
            size="small"
            key={JSON.stringify(option)}
            id={JSON.stringify(option)}
          />
        ))
      }
      size="small"
      value={selectedValues.map((v) => ({ label: v.name, value: v }))}
      isOptionEqualToValue={(option, value) =>
        option.value.id === value.value.id
      }
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value.id}>
            {option.label}
          </li>
        );
      }}
      limitTags={1}
      filterSelectedOptions
      disabled={!!error}
      noOptionsText="Loading..."
      renderInput={(params) => (
        <StyledFilterButton
          {...params}
          label="Production Company"
          variant="filled"
          size="small"
          placeholder="Production Company"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
