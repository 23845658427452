import {
  isArrayPropertyWithVerify,
  isPropertyWithVerify,
  validateNotNull,
} from '../guards';

export interface Authority<T> {
  value: T;
  source: Source;
}

export const validateAuthorityOf =
  <T>(
    type: string,
    checkType: (t: T) => boolean
  ): ((argument: Authority<T>) => boolean) =>
  (argument: any) => {
    validateNotNull(
      argument,
      `Invalid Authority<${type}> type, object is null`
    );
    isPropertyWithVerify(
      argument.source,
      validateSource,
      'Invalid Authority type, source is not a Source'
    );

    isPropertyWithVerify(
      argument.value,
      checkType,
      `Invalid Authority<${type}> type, value is not a ${type}`
    );

    return true;
  };

export const validateAuthorityOfArray =
  <T>(type: string, checkType: (t: T) => boolean) =>
  (argument: Authority<T[]>) => {
    validateNotNull(
      argument,
      `Invalid Authority<${type}[]> type, object is null`
    );

    isPropertyWithVerify(
      argument.source,
      validateSource,
      `Invalid Authority type, source is not a Source`
    );

    isArrayPropertyWithVerify(
      argument.value,
      checkType,
      `Invalid Authority<${type}[]> type, value is not an array of ${type}`
    );

    return true;
  };

export enum Source {
  IGNITE = 'Ignite',
  CORE_CATALOGUE_BRAND = 'CoreCatalogueBrand',
  CORE_CATALOGUE_TITLE = 'CoreCatalogueTitle',
  CORE_CATALOGUE_SERIES = 'CoreCatalogueSeries',
}

export const validateSource = (argument: any) => {
  if (Object.values(Source).includes(argument)) {
    return true;
  }
  throw new TypeError(
    `Invalid Source value '${argument}', should be one of 'IGNITE', 'CORE_CATALOGUE_BRAND', 'CORE_CATALOGUE_TITLE' or 'CORE_CATALOGUE_SERIES'`
  );
};
export const isSourceIgnite = <T>(authority?: Authority<T>): boolean =>
  !!authority && authority.source === Source.IGNITE;
