import { useEffect, useState } from 'react';

const heightOfOneTitle = 74; // this is the height in px of one title row + 10px margin

const useViewportHeight = () => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const titlesToDisplayCount = Math.ceil(
    (1.5 * viewportHeight) / heightOfOneTitle
  );

  const handleResizeViewport = () => {
    setViewportHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResizeViewport);
    return () => {
      window.removeEventListener('resize', handleResizeViewport);
    };
  }, []);

  return { viewportHeight, titlesToDisplayCount };
};

export default useViewportHeight;
