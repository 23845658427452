import { parseISO, format, formatDistanceToNow } from 'date-fns';

export interface FormatDateToRelativeText {
  formated: string;
  relativeFromNow: string;
  parsedDate: Date | string;
}

/**
 * Returns a formatted and relative text representation of the given ISO date string.
 *
 * @param {string} [date] The ISO date string to format. If not provided, returns placeholders for the formatted and relative text.
 * @returns {{
 *   parsedDate: Date|string;
 *   formatted: string;
 *   relativeFromNow: string;
 * }} An object containing the parsed date object, formatted date string, and relative text representation of the date.
 */
const formatDateToRelativeText = (
  date: string | undefined
): FormatDateToRelativeText => {
  if (typeof date === 'undefined') {
    return { formated: '-', relativeFromNow: '-', parsedDate: '-' };
  }
  const parsedDate = parseISO(date);
  const formated = format(parsedDate, 'EEEE, do MMMM hh:mm a');
  const relativeFromNow = formatDistanceToNow(parsedDate, {
    addSuffix: true,
  }).replace('over', '');

  return {
    parsedDate,
    formated,
    relativeFromNow,
  };
};

export { formatDateToRelativeText };
