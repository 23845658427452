/* eslint-disable no-underscore-dangle */
import { envConfig } from './loadEnvConfig';
import { getOktaConfig } from './getOktaConfig';

const oktaConfig = getOktaConfig();

let cachedConfig;

export const getConfig = () => {
  if (cachedConfig) return cachedConfig;
  if (process.env.NODE_ENV === 'local') {
    cachedConfig = {
      ...envConfig,
      ...oktaConfig,
      BUSINESS_API_BASE_URI: 'http://localhost:3080/business',
      IGNITE_API_BASE_URI: 'http://localhost:3080',
    };
    return cachedConfig;
  }

  if (process.env.NODE_ENV === 'test') {
    cachedConfig = {
      ...envConfig,
      ...oktaConfig,
    };
    return cachedConfig;
  }

  if (window._env_) {
    cachedConfig = window._env_;
    return cachedConfig;
  }

  cachedConfig = {
    ...envConfig,
    ...oktaConfig,
  };
  return cachedConfig;
};
