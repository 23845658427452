import { Button, ButtonProps } from '@mui/material';

type LinkButtonProps = ButtonProps;

const LinkButton = ({ children, ...props }: LinkButtonProps) => (
  <Button
    {...props}
    variant="text"
    onClick={(event) => {
      if (props?.onClick) {
        event?.preventDefault();
        props.onClick(event);
      }
    }}
  >
    {children}
  </Button>
);

export { LinkButton };
