import { colours } from 'utils/theme/colours';
import { styled } from '@mui/material';

const StyledEmptyTitleRow = styled('tr')`
  text-align: center;
  background-color: transparent;
  color: ${colours.text.secondary};
`;

interface Props {
  visible?: boolean;
}

export const ListEmpty = ({ visible = true }: Props) => {
  if (!visible) {
    return null;
  }
  return (
    <tbody>
      <StyledEmptyTitleRow style={{ background: 'none', marginTop: 200 }}>
        <td style={{ background: 'none', paddingTop: 72 }}>
          No results found 😔
        </td>
      </StyledEmptyTitleRow>
    </tbody>
  );
};
