import { getConfig } from 'utils/config';
import axios from 'axios';

type IgniteVersionResponse = {
  service: string;
  version: string;
  commitId: string;
  commitMessage: string;
  commitTime: string;
  imageTag: string;
};

export const fetchIgniteVersion = async () => {
  const url = getConfig().IGNITE_VERSION_URI;
  const response = await axios.get<IgniteVersionResponse>(url);
  return response.data.version;
};
