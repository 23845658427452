import { IgniteTitleMin } from 'domain/igniteTitle/titles';
import { TitleTypes } from 'domain/igniteTitle/details/catalogue/titleType/titleType';
import colours from 'utils/theme/colours';
import { StarOutline, Theaters, Tv } from '@mui/icons-material';

interface Props {
  title: IgniteTitleMin;
}

const TitleIcon = ({ title }: Props) => {
  const { titleType } = title;

  switch (titleType) {
    case TitleTypes.SERIES:
      return <Tv sx={{ color: colours.surfieGreen, fontSize: 18 }} />;

    case TitleTypes.FILM:
      return <Theaters sx={{ color: colours.surfieGreen, fontSize: 18 }} />;

    case TitleTypes.SPECIAL:
      return <StarOutline sx={{ color: colours.surfieGreen, fontSize: 18 }} />;

    default:
      return null;
  }
};

export default TitleIcon;
