import { ProjectStateTransitions } from 'features/ProjectStatus';
import { validateTeam, Team } from './team/team';
import { validateLanguage, Language } from './language/language';
import { validateCountry, Country } from './country/country';
import { validateCurrency, Currency } from './currency/currency';
import {
  validateProjectStatus,
  ProjectStatus,
} from './projectStatus/projectStatus';
import {
  validateCard,
  Card,
  DeliverablesCard,
  validateDeliverablesCard,
} from './card/card';
import { OfcomGenre, validateOfcomGenre } from './ofcomGenre/ofcomGenre';
import {
  ProductionStatus,
  validateProductionStatus,
} from '../igniteTitle/productionStatus';
import { NoteTypes, validateNoteType } from '../igniteTitle/notes';
import {
  AmortisationProfile,
  validateAmortisationProfile,
} from './amortisationProfile/amortisationProfile';
import {
  Genre,
  SubGenre,
  validateGenre,
  validateSubGenre,
} from '../igniteTitle/details/catalogue/genre';
import {
  validateNotNull,
  hasNonEmptyArrayPropertyWithVerify,
  isNonEmptyString,
  isNumber,
  isArrayPropertyWithVerify,
  isPropertyWithVerify,
} from '../guards';

export type Datasets = {
  teams: Team[];
  genres: Genre[];
  subGenres: SubGenre[];
  languages: Language[];
  countries: Country[];
  currencies: Currency[];
  projectStatuses: ProjectStatus[];
  requiredCommissioningApprovers: number;
  productionStatuses: ProductionStatus[];
  projectStateTransitions: ProjectStateTransitions;
  producerStatuses: string[];
  supportUri: string;
  cards: Card[];
  deliverablesCards: DeliverablesCard[];
  noteTypes: NoteTypes[];
  ofcomGenres: OfcomGenre[];
  ofcomCertifications: string[];
  amortisationProfiles: AmortisationProfile[];
};

export const validateDatasets = (argument: Datasets) => {
  validateNotNull(argument, `Invalid Datasets type, object is null`);
  hasNonEmptyArrayPropertyWithVerify(
    argument,
    'Datasets',
    'teams',
    validateTeam,
    'team'
  );
  hasNonEmptyArrayPropertyWithVerify(
    argument,
    'Datasets',
    'genres',
    validateGenre,
    'genre'
  );
  hasNonEmptyArrayPropertyWithVerify(
    argument,
    'Datasets',
    'subGenres',
    validateSubGenre,
    'subGenre'
  );
  hasNonEmptyArrayPropertyWithVerify(
    argument,
    'Datasets',
    'languages',
    validateLanguage,
    'language'
  );
  hasNonEmptyArrayPropertyWithVerify(
    argument,
    'Datasets',
    'countries',
    validateCountry,
    'country'
  );
  hasNonEmptyArrayPropertyWithVerify(
    argument,
    'Datasets',
    'currencies',
    validateCurrency,
    'currency'
  );
  hasNonEmptyArrayPropertyWithVerify(
    argument,
    'Datasets',
    'projectStatuses',
    validateProjectStatus,
    'projectStatus'
  );
  isPropertyWithVerify(
    argument.requiredCommissioningApprovers,
    isNumber,
    `Invalid Datasets type, requiredCommissioningApprovers is not a number`
  );
  if (argument.productionStatuses != null) {
    isArrayPropertyWithVerify(
      argument.productionStatuses,
      validateProductionStatus,
      'Invalid Datasets type, productionStatuses is not a ProductionStatus[]'
    );
  }

  if (argument.supportUri != null) {
    isPropertyWithVerify(
      argument.supportUri,
      isNonEmptyString,
      'Invalid Datasets type, supportUri is not a non-empty string'
    );
  }

  if (argument.cards != null) {
    hasNonEmptyArrayPropertyWithVerify(
      argument,
      'Datasets',
      'cards',
      validateCard,
      'cards'
    );
  }

  if (argument.deliverablesCards != null) {
    isArrayPropertyWithVerify(
      argument.deliverablesCards,
      validateDeliverablesCard,
      `Invalid deliverablesCards type, should be Array of DeliverablesCard`
    );
  }

  if (argument.noteTypes != null) {
    hasNonEmptyArrayPropertyWithVerify(
      argument,
      'Datasets',
      'noteTypes',
      validateNoteType,
      'noteTypes'
    );
  }

  isArrayPropertyWithVerify(
    argument.ofcomGenres,
    validateOfcomGenre,
    'Invalid Datasets type, ofcomGenres is not a OfcomGenre[]'
  );

  isArrayPropertyWithVerify(
    argument.ofcomCertifications,
    isNonEmptyString,
    'Invalid Datasets type, ofcomCertifications is not a non-empty string[]'
  );

  isArrayPropertyWithVerify(
    argument.amortisationProfiles,
    validateAmortisationProfile,
    'Invalid Datasets type, amortisationProfiles is not a AmortisationProfile[]'
  );

  return true;
};
