import { styled, Typography } from '@mui/material';

const StyledProductName = styled(Typography)`
  color: #ffffff;
`;

export const ProductName = () => (
  <StyledProductName variant="h1" fontSize="20px" ml="16px" aria-hidden>
    Ignite
  </StyledProductName>
);
