import { useEffect } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';
import { StringParam, useQueryParam } from 'use-query-params';
import { FormControlLabel, FormGroup, Switch, styled } from '@mui/material';
import { EventActions, useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useLocation } from 'react-router-dom';

const ActiveSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.info.light,
    '&:hover': {
      backgroundColor: theme.palette.info.main,
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.info.light,
  },
}));

export const ArchivedProjectsToggle = () => {
  const { pathname } = useLocation();
  const [cachedShowArchived, setCachedShowArchived] = useLocalStorage(
    'filter_show_archived',
    false
  );
  const [paramValue, setParamValue] = useQueryParam(
    'showArchived',
    StringParam
  );
  const isArchivedShown = paramValue === 'true';
  const { logDashboardFilterEvent } = useGoogleAnalytics();

  useEffect(() => {
    if (!paramValue && pathname.includes('/dashboard')) {
      setParamValue(cachedShowArchived?.toString());
    }
  }, []);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <ActiveSwitch
            checked={Boolean(isArchivedShown)}
            onChange={() => {
              logDashboardFilterEvent(
                EventActions.DashboardActions.Archive,
                !isArchivedShown
              );
              setCachedShowArchived(isArchivedShown);
              return isArchivedShown
                ? setParamValue('false')
                : setParamValue('true');
            }}
            id="archive-projects-toggle"
          />
        }
        label="Show archived projects"
        labelPlacement="start"
        sx={{ color: 'white' }}
      />
    </FormGroup>
  );
};

export default ArchivedProjectsToggle;
