import { AxiosResponse } from 'axios';
import { snackbarVariants } from 'components/Snackbar';

export const handleSuccess = (response: AxiosResponse) => {
  const { method } = response.config;
  if (method === 'put' || method === 'patch') {
    snackbarVariants.success('Updated');
  }
  if (method === 'post') {
    if (!response.config.url?.includes('validate')) {
      snackbarVariants.success('Created');
    }
  }
  return response;
};
