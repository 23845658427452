export enum Card {
  BasicDetails = 'BasicDetails',
  TransmissionAndDelivery = 'TransmissionAndDelivery',
  TitleStructure = 'TitleStructure',
  ProgrammeDetails = 'ProgrammeDetails',
  FinanceDetails = 'FinanceDetails',
  CommissioningApprovals = 'CommissioningApprovals',
  AgreementsAndRegulatory = 'AgreementsAndRegulatory',
  DealTerms = 'DealTerms',
  Profitability = 'Profitability',
  FinancePlan = 'FinancePlan',
}
export enum DeliverablesCard {
  DeliverablesTrigger = 'DeliverablesTrigger',
  ProducerContacts = 'ProducerContacts',
}

export const validateCard = (argument: Card) => {
  if (Object.values(Card).includes(argument)) {
    return true;
  }
  throw new TypeError(
    `Invalid Card value '${argument}', should be one of [${Object.values(
      Card
    ).join(', ')}]`
  );
};

export const validateDeliverablesCard = (argument: DeliverablesCard) => {
  if (Object.values(DeliverablesCard).includes(argument)) {
    return true;
  }
  throw new TypeError(
    `Invalid DeliverablesCard value '${argument}', should be one of [${Object.values(
      DeliverablesCard
    ).join(', ')}]`
  );
};
