import { Status, validateStatus } from 'domain/datasets/status/status';
import {
  SubStatus,
  validateSubStatus,
} from 'domain/datasets/subStatus/substatus';
import {
  validateNotNull,
  isArrayPropertyWithVerify,
  isPropertyWithVerify,
} from 'domain/guards';

export type ProjectStatus = {
  status: Status;
  substatuses: SubStatus[];
};

export const validateProjectStatus = (argument: ProjectStatus) => {
  validateNotNull(argument, `Invalid ProjectStatus type, object is null`);
  isPropertyWithVerify(
    argument.status,
    validateStatus,
    'Invalid ProjectStatus type, status is not a Status'
  );
  isArrayPropertyWithVerify(
    argument.substatuses,
    validateSubStatus,
    'Invalid ProjectStatus type, substatuses is not a SubStatus[]'
  );
  return true;
};
