import axios from 'axios';
import * as rax from 'retry-axios';
import { handleSuccess } from 'utils/handleSuccess';
import { handleError } from 'utils/handleError';
import { getConfig } from 'utils/config';

const config = getConfig();
const timeout = 30000;

const { IGNITE_API_BASE_URI } = config;

// We need to set a httpAgent in here due to a bug in Node 20 breaking out pacts
// In pactSetup.js we are setting the global.httpAgent
// If we import it in here, at build time, vite complains that it's trying to import
// http which is a node library, not a browser one
export const igniteApiClient = axios.create({
  baseURL: IGNITE_API_BASE_URI,
  timeout,
  headers: {
    Accept: 'application/json',
  },
  withCredentials: true,
  raxConfig: {
    retry: 3,
    noResponseRetries: 2,
  },
  httpAgent:
    config.NODE_ENV === 'test' && (global as any).httpAgent
      ? (global as any).httpAgent
      : undefined,
});

igniteApiClient.defaults.raxConfig = {
  instance: igniteApiClient,
};

igniteApiClient.interceptors.response.use(handleSuccess);
const retryInterceptor = rax.attach(igniteApiClient);
const errorInterceptor = igniteApiClient.interceptors.response.use(
  undefined,
  handleError
);

export const ejectIgniteApiClientInterceptors = () => {
  igniteApiClient.interceptors.response.eject(errorInterceptor);
  igniteApiClient.interceptors.response.eject(retryInterceptor);
};
