import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

import { getDomainEnv } from 'utils/getDomainEnv';
import { Logo } from './Logo';
import { ProductName } from './ProductName';
import { Environment } from './Environment';

const StyledProductInfo = styled(Link)`
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

export const NavInfo = () => (
  <StyledProductInfo to="/dashboard">
    <Logo />
    <ProductName />
    <Environment environment={getDomainEnv()} />
  </StyledProductInfo>
);
