import axios from 'axios';
import * as rax from 'retry-axios';
import { getConfig } from 'utils/config';

const config = getConfig();
const timeout = 30000;

const { SECURITY_PRODUCT, SECURITY_BASE_URI } = config;

export const securityClient = axios.create({
  baseURL: SECURITY_BASE_URI,
  timeout,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest',
    'X-Security-Product': SECURITY_PRODUCT,
  },
  withCredentials: true,
  raxConfig: {
    retry: 3,
    noResponseRetries: 2,
  },
});

securityClient.defaults.raxConfig = {
  instance: securityClient,
};

const retryInterceptor = rax.attach(securityClient);

export const ejectSecurityClientInterceptors = () => {
  securityClient.interceptors.response.eject(retryInterceptor);
};
