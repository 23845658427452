import { styled } from '@mui/material';
import { colours } from 'utils/theme/colours';
import { IgniteTitleMin } from 'domain/igniteTitle/titles';
import { textTruncate } from '../../Styles';

const StyledProjectSubstatus = styled('div')`
  ${textTruncate};
  color: ${colours.secondary.main};
  font-size: 13px;
`;

const getProjectSubstatus = (title: IgniteTitleMin) => {
  const { subStatus } = title.projectStatus;
  return <StyledProjectSubstatus>{subStatus?.name}</StyledProjectSubstatus>;
};
interface Props {
  title: IgniteTitleMin;
}

export const ProjectSubstatus = ({ title }: Props) =>
  getProjectSubstatus(title);
