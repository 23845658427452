import { isTypeFromValidate } from '../../../../../utils/isTypeFromValidate';
import {
  hasPropertyWithValue,
  isArrayPropertyWithVerify,
  isPropertyWithVerify,
  validateNotNull,
} from '../../../../guards';
import { Authority, validateAuthorityOf } from '../../../authority';
import {
  Brand,
  BrandOptional,
  validateBrand,
  validateBrandOptional,
} from '../brand';
import {
  PrimaryConfiguration,
  PrimaryConfigurationData,
  SecondaryConfiguration,
  SecondaryConfigurationData,
  validatePrimaryConfiguration,
  validateSecondaryConfiguration,
} from '../configuration';
import { Title, validateTitle } from '../title';
import { SeriesDetails, validateSeriesDetails } from './seriesDetails';
import { TitleStructure, validateTitleStructure } from './titleStructure';

export enum TitleTypes {
  SERIES = 'SERIES',
  FILM = 'FILM',
  SPECIAL = 'SPECIAL',
}

export type TitleType = SeriesType | FilmType | SpecialType;

/**
 * Series Type
 */

export interface TitleConfigurations {
  primaryConfiguration: Nullable<PrimaryConfiguration>;
  secondaryConfigurations: Authority<SecondaryConfiguration>[];
}
export interface SeriesType extends TitleConfigurations {
  type: 'SERIES';
  brand: Brand;
  seriesDetails: Authority<SeriesDetails>;
  titleStructure: TitleStructure;
}

export const validateSeriesType = (
  titleType: SeriesType
): titleType is SeriesType => {
  validateNotNull(titleType, `Invalid Series type, object is null`);
  hasPropertyWithValue(titleType, 'series', 'type', TitleTypes.SERIES);
  isPropertyWithVerify(
    titleType.brand,
    validateBrand,
    `Invalid series type, brand is invalid`
  );

  isPropertyWithVerify(
    titleType.seriesDetails,
    validateAuthorityOf('seriesDetails', validateSeriesDetails),
    `Invalid series type, seriesDetails is invalid`
  );

  isPropertyWithVerify(
    titleType.titleStructure,
    validateTitleStructure,
    `Invalid series type, titleStructure is invalid`
  );

  if (titleType.primaryConfiguration) {
    validatePrimaryConfiguration(titleType.primaryConfiguration);
  }

  if (titleType.secondaryConfigurations) {
    isArrayPropertyWithVerify(
      titleType.secondaryConfigurations,
      validateAuthorityOf(
        'secondaryConfigurations',
        validateSecondaryConfiguration
      ),
      `Invalid series type, secondaryConfigurations is invalid`
    );
  }

  return true;
};

export const isSeriesType = (titleType: TitleType): titleType is SeriesType =>
  isTypeFromValidate(validateSeriesType, titleType as SeriesType);

/**
 * Film Type
 */
export interface FilmType {
  type: 'FILM';
  title: Title;
  titleStructure: TitleStructure;
}

export const validateFilmType = (
  titleType: FilmType
): titleType is FilmType => {
  validateNotNull(titleType, `Invalid Film type, object is null`);
  hasPropertyWithValue(titleType, 'film', 'type', TitleTypes.FILM);
  isPropertyWithVerify(
    titleType.title,
    validateTitle,
    'Invalid Film type, title is invalid'
  );
  isPropertyWithVerify(
    titleType.titleStructure,
    validateTitleStructure,
    'Invalid Film type, titleStructure is invalid'
  );
  return true;
};

export const isFilmType = (titleType: TitleType): titleType is FilmType =>
  isTypeFromValidate(validateFilmType, titleType as FilmType);

/**
 * Special Type
 */
export interface SpecialType {
  type: 'SPECIAL';
  brand: BrandOptional;
  title: Title;
  titleStructure: TitleStructure;
}

export interface CatalogueConfgurations {
  primary: Nullable<PrimaryConfigurationData>;
  secondaries: Authority<SecondaryConfigurationData>[];
}
export const validateSpecialType = (
  titleType: SpecialType
): titleType is SpecialType => {
  validateNotNull(titleType, `Invalid Special type, object is null`);
  hasPropertyWithValue(titleType, 'Special', 'type', TitleTypes.SPECIAL);
  isPropertyWithVerify(
    titleType.brand,
    validateBrandOptional,
    'Invalid Special type, brand is invalid'
  );
  isPropertyWithVerify(
    titleType.title,
    validateTitle,
    'Invalid Special type, title is invalid'
  );
  isPropertyWithVerify(
    titleType.titleStructure,
    validateTitleStructure,
    'Invalid Special type, titleStructure is invalid'
  );
  return true;
};

export const isSpecialType = (titleType: TitleType): titleType is SpecialType =>
  isTypeFromValidate(validateSpecialType, titleType as SpecialType);

export const validateTitleType = (argument: TitleType) => {
  validateNotNull(argument, `Invalid CatalogueTitleInfo type, object is null`);

  if (
    isTypeFromValidate(validateSeriesType, argument as SeriesType) ||
    isTypeFromValidate(validateFilmType, argument as FilmType) ||
    isTypeFromValidate(validateSpecialType, argument as SpecialType)
  ) {
    return true;
  }

  throw new TypeError(
    `Invalid title type, ${JSON.stringify(
      argument,
      undefined,
      2
    )} is not an allowed value`
  );
};
