import { fetchDatasets } from 'api';
import { Datasets } from 'domain/datasets';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

const useDatasets = (): UseQueryResult<Datasets> & { datasets?: Datasets } => {
  const dataSetsResponse = useQuery<Datasets>({
    queryKey: ['datasets'],
    queryFn: fetchDatasets,
  });
  return { ...dataSetsResponse, datasets: dataSetsResponse.data };
};

export { useDatasets };
