import {
  validateNotNull,
  hasNonEmptyStringProperty,
  isNumber,
  isPropertyWithVerify,
} from 'domain/guards';

export type Currency = {
  alpha3: string;
  name: string;
  minorUnit: number;
  symbol: string;
};

export const validateCurrency = (argument: Currency) => {
  validateNotNull(argument, `Invalid Currency type, object is null`);
  hasNonEmptyStringProperty(argument, 'currency', 'alpha3');
  hasNonEmptyStringProperty(argument, 'currency', 'name');
  isPropertyWithVerify(
    argument.minorUnit,
    isNumber,
    'Invalid Currency type, minorUnit is not a number'
  );
  hasNonEmptyStringProperty(argument, 'currency', 'symbol');
  return true;
};
