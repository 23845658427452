import {
  validateProducer,
  validateBroadcaster,
  Broadcaster,
  Producer,
  Businesses,
  BusinessData,
} from 'domain/business';
import {
  IgniteTitle,
  IgniteTitleFilterParams,
  IgniteTitleMin,
  TitleDetails,
  validateTitleDetails,
  validateIgniteTitleMin,
} from 'domain/igniteTitle/titles';
import { Datasets, validateDatasets } from 'domain/datasets';
import {
  CatalogueBrandResponse,
  AthenaBrand,
  AthenaFilm,
  AthenaSpecial,
  validateAthenaBrand,
  validateFilmPlusData,
  validateAthenaSpecial,
  validateCatalogueBrandResponse,
  validateAthenaSeries,
  AthenaSeries,
} from 'domain/coreCatalogue';
import { handleError } from 'utils/handleError';
import { UserProfile } from 'domain/user';
import { SearchResponse, validateSearchResponse } from 'domain/searchResponse';
import { FilmCatalogueLinkBody } from 'domain/reconciliation';

import {
  businessesApiClient,
  igniteApiClient,
  securityClient,
} from './clients';

/**
 * Fetches a session token, user's google profile and access token
 */
const createSession = (payload: { oneTimeCode: string }) =>
  securityClient({
    method: 'post',
    url: '/sessions',
    data: payload,
  })
    .then((response) => response.data.session)
    .catch(handleError);

/**
 * Revokes user's session token
 */
export const revokeSession = () =>
  securityClient({
    method: 'delete',
    url: '/sessions',
  })
    .then((response) => response.data)
    .catch(handleError);

/**
 * Fetches user's info
 */
export const fetchUserInfo = (): Promise<UserProfile> =>
  igniteApiClient({
    method: 'get',
    url: '/userinfo',
  })
    .then((response) => response.data)
    .catch(handleError);

/**
 * Creates a new title
 */
export const createTitle = (payload: TitleDetails) => {
  validateTitleDetails(payload);
  return igniteApiClient({
    method: 'post',
    url: '/titles',
    data: payload,
  })
    .then((response) => response.data)
    .catch(handleError);
};

/**
 * SearchQuery
 * term - the search query in lucene format
 * offset - pagination offset, starting index 0
 * limit - number of records to return from offset
 */
interface SearchQuery {
  term?: string;
  offset: number;
  limit: number;
}

/**
 * Searches ignite for titles
 */
export const searchTitles = (
  query: SearchQuery,
  filters: Partial<IgniteTitleFilterParams>
): Promise<SearchResponse> => {
  const emptyResponse: SearchResponse = {
    ...query,
    totalHits: 0,
    results: [],
  };
  if (query.term === undefined) {
    return Promise.resolve(emptyResponse);
  }

  return igniteApiClient({
    method: 'get',
    url: '/search/min',
    params: {
      ...query,
      ...filters,
    },
    paramsSerializer: {
      encode: (params) => encodeURIComponent(params),
      indexes: null,
    },
  })
    .then((response) => response.data)
    .then((searchResponse) => {
      searchResponse.results.forEach(validateIgniteTitleMin);
      validateSearchResponse(searchResponse);
      return searchResponse;
    })
    .catch(handleError);
};

/**
 * Searches catalogue for Brands
 * @param term - the search query in lucene format
 * @param offset - pagination offset, starting index 0
 * @param limit - number of records to return from offset
 *
 * @returns Promise<CatalogueBrandResponse[]>
 */
export const catalogueSearchBrand = (
  term: string,
  offset = 0,
  limit = 100
): Promise<CatalogueBrandResponse[]> => {
  return igniteApiClient({
    method: 'get',
    url: '/catalogue/search/brand',
    params: {
      term,
      offset,
      limit,
    },
  })
    .then((response) => response.data)
    .then((brands: CatalogueBrandResponse[]) => {
      brands.every(validateCatalogueBrandResponse);
      return brands;
    })
    .catch(handleError);
};

/**
 * Search series by brand ccid
 */

export const searchCatalogueSeriesByBrandCcid = (
  brandCcid: string
): Promise<AthenaSeries[]> =>
  igniteApiClient({
    method: 'get',
    url: '/catalogue/brand/series/ccid',
    params: {
      brandCcid,
    },
  })
    .then((response) => response.data)
    .then((series) => {
      series.every(validateAthenaSeries);
      return series;
    })
    .catch(handleError);

/**
 * Searches catalogue for brands
 * TODO: remove this and use catalogueSearchBrand instead
 * @deprecated
 */
export const searchCatalogueBrand = (term: string): Promise<AthenaBrand[]> =>
  igniteApiClient({
    method: 'get',
    url: '/catalogue/brand',
    params: {
      term,
    },
  })
    .then((response) => response.data)
    .then((specials) => {
      specials.every(validateAthenaBrand);
      return specials;
    })
    .catch(handleError);

/**
 * Searches catalogue for specials without a brand (title level only)
 */
export const searchCatalogueTitleSpecial = (
  term: string
): Promise<AthenaSpecial[]> =>
  igniteApiClient({
    method: 'get',
    url: '/catalogue/special/title',
    params: {
      term,
    },
  })
    .then((response) => response.data)
    .then((specials) => {
      specials.every(validateAthenaSpecial);
      return specials;
    })
    .catch(handleError);

/**
 * Searches catalogue for films
 */
const searchCatalogueFilm = (term: string): Promise<AthenaFilm[]> =>
  igniteApiClient({
    method: 'get',
    url: '/catalogue/film',
    params: {
      term,
    },
  })
    .then((response) => response.data)
    .then((films) => {
      films.every(validateFilmPlusData);
      return films;
    })
    .catch(handleError);

/**
 * Fetches producers (aka producer businesses)
 */
export const fetchBusinesses: () => Promise<Businesses> = () => {
  const businessTypeParams = new URLSearchParams();
  businessTypeParams.append('businessType', 'producer');
  businessTypeParams.append('businessType', 'distributor');
  businessTypeParams.append('businessType', 'broadcaster');

  return businessesApiClient({
    method: 'get',
    url: '',
    params: businessTypeParams,
  })
    .then((response) => response.data)
    .then((businessResponse) => {
      const producers: Producer[] = [];
      const broadcasters: Broadcaster[] = [];
      businessResponse.forEach((b: BusinessData) => {
        if (b.isBroadcaster && validateBroadcaster(b)) {
          broadcasters.push(b);
        }

        if ((b.isProducer || b.isDistributor) && validateProducer(b)) {
          producers.push(b);
        }
      });
      return {
        producers,
        broadcasters,
      };
    })
    .catch(handleError);
};

const handlePayload = (payload: { count: string; lastId?: string }) => {
  if (payload == null) {
    return {};
  }
  return payload.lastId ? payload : { count: payload.count };
};

/**
 * Fetches all titles (minified)
 */
export const fetchTitles = (
  payload: {
    count: string;
    lastId?: string;
  },
  query: Partial<IgniteTitleFilterParams>
): Promise<IgniteTitleMin[]> =>
  igniteApiClient({
    method: 'get',
    url: '/titles/min',
    params: {
      ...handlePayload(payload),
      ...query,
    },
    paramsSerializer: {
      encode: (params) => encodeURIComponent(params),
      indexes: null,
    },
  })
    .then((response) => {
      const { titles } = response.data;
      titles.map(validateIgniteTitleMin);
      return titles;
    })
    .catch(handleError);

/**
 * Fetches a single title by id
 */
export const fetchTitleById = (id: string): Promise<IgniteTitle> => {
  return igniteApiClient({
    method: 'get',
    url: `/titles/${id}`,
  })
    .then((response) => response.data)
    .catch(handleError);
};

/**
 * Updates a title
 */
export const updateTitleById = (
  id: string,
  titleDetail: TitleDetails
): Promise<IgniteTitle> =>
  igniteApiClient({
    method: 'put',
    url: `/titles/${id}`,
    data: titleDetail,
  })
    .then((response) => response.data)
    .catch(handleError);

/**
 * Retrieves config datatsets from Ignite
 */
export const fetchDatasets = (): Promise<Datasets> =>
  igniteApiClient({
    method: 'get',
    url: '/datasets',
  })
    .then((response) => response.data)
    .then((datasets) => {
      validateDatasets(datasets);
      return datasets;
    })
    .catch(handleError);

/**
 * Link a film to a core catalogue film
 */
export const linkFilm = (
  titleId: string,
  body: FilmCatalogueLinkBody
): Promise<IgniteTitle> =>
  igniteApiClient({
    method: 'patch',
    url: `/titles/${titleId}/catalogue/film`,
    data: body,
  })
    .then((response) => response.data)
    .catch(handleError);

export * from './alternateNames';

export default {
  createSession,
  revokeSession,
  createTitle,
  catalogueSearchBrand,
  fetchBusinesses,
  fetchDatasets,
  searchTitles,
  searchCatalogueSeriesByBrandCcid,
  searchCatalogueBrand,
  searchCatalogueTitleSpecial,
  searchCatalogueFilm,
  fetchUserInfo,
  fetchTitles,
  fetchTitleById,
  updateTitleById,
  linkFilm,
};
