import {
  validateNotNull,
  isPropertyWithVerify,
  isNonEmptyString,
  hasNonEmptyArrayPropertyWithVerify,
} from 'domain/guards';

export type OfcomGenre = {
  superGenre: string;
  subGenres: string[];
};

export const validateOfcomGenre = (argument: OfcomGenre) => {
  validateNotNull(argument, 'Invalid OfcomGenre, value is null');
  isPropertyWithVerify(
    argument.superGenre,
    isNonEmptyString,
    'Invalid OfcomGenre.superGenre, should be string'
  );
  hasNonEmptyArrayPropertyWithVerify(
    argument,
    'OfcomGenre',
    'subGenres',
    isNonEmptyString,
    'non-empty list of non-empty string'
  );
  return true;
};
