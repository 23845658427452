import {
  validateNotNull,
  hasNonEmptyStringProperty,
  hasNonEmptyArrayPropertyWithVerify,
  isNonEmptyString,
} from 'domain/guards';

export type Team = {
  name: string;
  types: string[];
};

export const validateTeam = (argument: Team) => {
  validateNotNull(argument, `Invalid Team type, object is null`);
  hasNonEmptyStringProperty(argument, 'team', 'name');
  hasNonEmptyArrayPropertyWithVerify(
    argument,
    'team',
    'types',
    isNonEmptyString,
    'non empty string'
  );
  return true;
};
