import {
  hasNonEmptyStringProperty,
  isArrayPropertyWithVerify,
  isNonEmptyString,
  isNumber,
  isPropertyWithVerify,
  validateIsObject,
  validateNotNull,
} from '../guards';

export const validateProjectStatus = (argument: any) => {
  validateNotNull(argument, `Invalid ProjectStatus, object is null`);
  isPropertyWithVerify(
    argument.status,
    validateStatus,
    'Invalid ProjectStatus type, status is not a Status'
  );
  if (argument.subStatus != null) {
    isPropertyWithVerify(
      argument.subStatus,
      validateSubStatus,
      'Invalid ProjectStatus type, subStatus is not a SubStatus'
    );
  }
  return true;
};

export const validateStatus = (argument: any) => {
  hasNonEmptyStringProperty(argument, 'string', 'name');
  isPropertyWithVerify(
    argument.index,
    isNumber,
    'Invalid Status type, index is not a number'
  );
  if (argument.description != null) {
    isPropertyWithVerify(
      argument.description,
      isNonEmptyString,
      'Invalid Status type, description is not a non-empty string'
    );
  }
  return true;
};

export const validateSubStatus = (argument: any) => {
  validateNotNull(argument, `Invalid SubStatus type, object is null`);
  hasNonEmptyStringProperty(argument, 'subStatus', 'name');
  isPropertyWithVerify(
    argument.index,
    isNumber,
    'Invalid SubStatus type, index is not a number'
  );
  if (argument.description != null) {
    hasNonEmptyStringProperty(argument, 'subStatus', 'description');
  }
  return true;
};

export const validateApprover = (argument: any) => {
  validateIsObject(argument, 'Invalid approver type is not Object');
  if (argument.name) hasNonEmptyStringProperty(argument, 'String', 'name');
  if (argument.title) hasNonEmptyStringProperty(argument, 'String', 'title');

  return true;
};
export const validateCommissioningApprovals = (argument: any) => {
  validateNotNull(
    argument,
    'Invalid CommissioningApprovals type, object is null'
  );
  validateProjectStatus(argument.projectStatus);
  isArrayPropertyWithVerify(
    argument.approvers,
    validateApprover,
    'Invalid CommissioningApprovals type, approvers is not Approver[]'
  );
  if (argument.notes) {
    hasNonEmptyStringProperty(argument, 'String', 'notes');
  }
  if (argument.completedAt) {
    hasNonEmptyStringProperty(argument, 'String', 'completedAt');
  }
  return true;
};

export const validateProjectState = (argument: any) => {
  validateNotNull(argument, 'Invalid ProjectState type, object is null');
  validateProjectStatus(argument.projectStatus);
  isArrayPropertyWithVerify(
    argument.approvals,
    validateCommissioningApprovals,
    'Invalid ProjectState type, approvals is not a CommissioningApprovals[]'
  );
  return true;
};
