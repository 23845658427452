import {
  isPropertyWithVerify,
  isNonEmptyString,
  isNumber,
  validateIsObject,
  validateNotNull,
  isBoolean,
} from '../guards';

export interface Rights {
  dealTerms: DealTerms;
}

export interface InfiniteInt {
  isInfinite: true;
}

export interface PositiveInt {
  isInfinite: false;
  value: number;
}

export type InfinitePositiveInt = InfiniteInt | PositiveInt;

export const validateInfinitePositiveInt = (argument: InfinitePositiveInt) => {
  validateNotNull(argument, 'Invalid InfinitePositiveInt, value is null');
  validateIsObject(
    argument,
    'Invalid InfinitePositiveInt, value is not an object'
  );

  isPropertyWithVerify(
    argument.isInfinite,
    isBoolean,
    'Invalid InfinitePositiveInt.isInfinite, should be boolean'
  );

  switch (argument.isInfinite) {
    case true:
      break;

    default:
      isPropertyWithVerify(
        argument.value,
        isNumber,
        'Invalid InfinitePositiveInt.value, should be number'
      );
      break;
  }
  return true;
};

export interface DealTerms {
  producerPaidRuns?: InfinitePositiveInt;
  preclearedRuns?: InfinitePositiveInt;
  keyDealTerms?: string;
  keyPersons?: string;
  contractReference?: string;
  contractInstructions?: string;
  contractAndRightsItvContact?: string;
  legalAndBusinessAffairsItvContact?: string;
}

export const validateDealTerms = (argument: DealTerms) => {
  validateNotNull(argument, 'Invalid DealTerms, value is null');
  validateIsObject(argument, 'Invalid DealTerms, value is not an object');
  if (argument.producerPaidRuns != null) {
    isPropertyWithVerify(
      argument.producerPaidRuns,
      validateInfinitePositiveInt,
      'Invalid DealTerms.producerPaidRuns, should be InfinitePositiveInt'
    );
  }
  if (argument.preclearedRuns != null) {
    isPropertyWithVerify(
      argument.preclearedRuns,
      validateInfinitePositiveInt,
      'Invalid DealTerms.preclearedRuns, should be InfinitePositiveInt'
    );
  }
  if (argument.keyDealTerms != null) {
    isPropertyWithVerify(
      argument.keyDealTerms,
      isNonEmptyString,
      'Invalid DealTerms.keyDealTerms, should be string'
    );
  }
  if (argument.keyPersons != null) {
    isPropertyWithVerify(
      argument.keyPersons,
      isNonEmptyString,
      'Invalid DealTerms.keyPersons, should be string'
    );
  }
  if (argument.contractReference != null) {
    isPropertyWithVerify(
      argument.contractReference,
      isNonEmptyString,
      'Invalid DealTerms.contractReference, should be string'
    );
  }
  if (argument.contractInstructions != null) {
    isPropertyWithVerify(
      argument.contractInstructions,
      isNonEmptyString,
      'Invalid DealTerms.contractInstructions, should be string'
    );
  }
  if (argument.contractAndRightsItvContact != null) {
    isPropertyWithVerify(
      argument.contractAndRightsItvContact,
      isNonEmptyString,
      'Invalid DealTerms.contractAndRightsItvContact, should be string'
    );
  }
  if (argument.legalAndBusinessAffairsItvContact != null) {
    isPropertyWithVerify(
      argument.legalAndBusinessAffairsItvContact,
      isNonEmptyString,
      'Invalid DealTerms.legalAndBusinessAffairsItvContact, should be string'
    );
  }
  return true;
};

export const validateRights = (argument: Rights) => {
  validateNotNull(argument, 'Invalid Rights, value is null');
  isPropertyWithVerify(
    argument.dealTerms,
    validateDealTerms,
    'Invalid Rights.dealTerms, should be DealTerms'
  );
  return true;
};
