import { Launch } from '@mui/icons-material';
import { Link, Typography, styled } from '@mui/material';

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  display: 'flex',
  alignItems: 'center',
  gap: 1,
}));

const COMMISSIONING_SLATE_URL =
  'https://airtable.com/appRaiAeoakC1JYQr/pagJpRyN18GsE0iTJ?oVONo=sfsl7XF1BIqYDTuFu';

export const CommissioningSlateLink = () => (
  <StyledLink
    aria-label="Slate"
    title="Slate"
    href={COMMISSIONING_SLATE_URL}
    target="_blank"
  >
    <Typography component="span">Slate</Typography>
    <Launch fontSize="small" />
  </StyledLink>
);
