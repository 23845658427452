import { hasNonEmptyStringProperty, isPropertyWithVerify } from '../guards';
import { UserInfo, validateUserInfo } from './userInfo';

export interface NewArchivalMetadata {
  when: string;
  why: string;
}

export interface ArchivalMetadata {
  who: UserInfo;
  when: string;
  why: string;
}

export const validateArchival = (argument: ArchivalMetadata) => {
  isPropertyWithVerify(
    argument.who,
    validateUserInfo('Archival', 'who'),
    'Invalid Archival type, who is not a UserInfo'
  );
  hasNonEmptyStringProperty(argument, 'Archival', 'when');
  hasNonEmptyStringProperty(argument, 'Archival', 'why');
  return true;
};
