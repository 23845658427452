export const getDomainEnv = (): 'LOCAL' | 'QA' | 'STG' | '' => {
  const url = window.location.origin;
  switch (true) {
    case url.includes('localhost'):
      return 'LOCAL';
    case url.includes('ignite.qa'):
      return 'QA';
    case url.includes('ignite.stg'):
      return 'STG';
    default:
      return '';
  }
};
