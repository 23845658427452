import { useMemo } from 'react';

import { useFilterParam } from 'hooks/useFilterParam';
import useSort, { SortBy, SortOrder } from 'hooks/useSort';
import {
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
  styled,
} from '@mui/material';
import { capitalize } from 'lodash-es';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

type Option = {
  value: string | string[];
  name: string;
  indent?: boolean;
};

interface Props {
  filterKey: string;
  filterLabel: string;
  options: Option[];
  multiSelect?: boolean;
}

const createFilterOption = (opt: Option) => ({
  value: opt.value,
  name: opt.name,
});

const createSubFilterOption = (name: string): Option => ({
  ...createFilterOption({
    name,
    value: name,
  }),
  indent: true,
});

const UIFilter = ({
  filterKey,
  filterLabel,
  options,
  multiSelect = true,
}: Props) => {
  const { paramValues, toggleParamValue, clearParam } = useFilterParam(
    filterKey,
    multiSelect
  );
  const { logDashboardFilterEvent } = useGoogleAnalytics();

  const { setSort } = useSort();
  const sortTargetDate = (paramValue: string | string[]) => {
    setSort({
      sortBy: SortBy.TargetDate,
      order:
        paramValue === 'future' ? SortOrder.Ascending : SortOrder.Descending,
    });
  };

  const filterOptions: Option[] = useMemo(
    () =>
      options
        .map((opt) => {
          const filterOption = createFilterOption(opt);
          const subfilterOptions = Array.isArray(opt.value)
            ? opt.value.map(createSubFilterOption)
            : [];
          return [filterOption, ...subfilterOptions];
        })
        .flat(),
    [options]
  );

  const selectedParamValues: string[] = useMemo(
    () =>
      filterOptions
        .filter((opt) => {
          return Array.isArray(opt.value)
            ? opt.value.every((v) => (paramValues as string[]).includes(v))
            : (paramValues as string[]).includes(opt.value);
        })
        .map(({ name, value }) => (Array.isArray(value) ? name : value)),
    [filterOptions, paramValues]
  );

  return (
    <FilterButton
      onChange={(status) => {
        if (filterKey === 'targetPeriod') sortTargetDate(status);
        logDashboardFilterEvent(
          filterKey,
          [...selectedParamValues, status].join(', ')
        );
        return toggleParamValue(status);
      }}
      onClear={clearParam}
      options={filterOptions}
      selectedValues={selectedParamValues}
      label={filterLabel}
      multiple={multiSelect}
      disabled={options?.length === 0}
    />
  );
};

interface FilterButtonProps {
  onChange: (v: string | string[]) => void;
  onClear: () => void;
  options: Option[];
  selectedValues: string[];
  label: string;
  multiple: boolean;
  disabled: boolean;
}
const StyledFilterButton = styled(TextField)`
  & .MuiFilledInput-root {
    background-color: white !important;
    border-radius: 4px;
  }
`;

const FilterButton = ({
  onChange,
  onClear,
  options,
  selectedValues,
  label,
  multiple,
  disabled,
}: FilterButtonProps) => {
  return (
    <StyledFilterButton
      fullWidth
      disabled={disabled}
      variant="filled"
      size="small"
      select
      label={label}
      SelectProps={{
        renderValue: () => selectedValues.map(capitalize).join(', '),
        multiple,
        defaultValue: '',
      }}
      defaultValue=""
      value={multiple ? selectedValues : selectedValues[0] || ''}
      InputLabelProps={{ htmlFor: `${label}-filter` }}
      inputProps={{ id: `${label}-filter` }}
    >
      <MenuItem onClick={() => onClear()}>Clear</MenuItem>
      {options.map((option) => {
        return (
          <MenuItem
            key={option.name}
            value={option.value}
            onClick={() => onChange(option.value)}
            sx={{ paddingLeft: option.indent ? '24px' : null }}
            selected={!!selectedValues.find((value) => value === option.name)}
          >
            {multiple && (
              <Checkbox
                checked={
                  !!selectedValues.find((value) => value === option.name)
                }
              />
            )}
            <ListItemText primary={option.name} />
          </MenuItem>
        );
      })}
    </StyledFilterButton>
  );
};

export { UIFilter };
