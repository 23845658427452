import { Flex } from 'components/Layout';
import { CreateNewTitle } from './CreateNewTitle';
import { LogoutButton } from './LogoutButton';
import { CommissioningSlateLink } from './CommissioningSlateLink';

interface Props {
  canCreate?: boolean;
}

export const NavActions = ({ canCreate = false }: Props) => {
  return (
    <Flex gap={2}>
      <CommissioningSlateLink />
      {canCreate && <CreateNewTitle />}
      <LogoutButton />
    </Flex>
  );
};
