import {
  isArrayPropertyWithVerify,
  isPropertyWithVerify,
  validateNotNull,
} from '../../../guards';
import { Authority, validateAuthorityOf } from '../../authority';
import {
  CatalogueTitleInfo,
  validateCatalogueTitleInfo,
} from './titleType/catalogueTitleInfo';
import { AlternateName, validateAlternateName } from './alternateName';

export interface Title {
  catalogue: Authority<CatalogueTitleInfo>;
  alternateNames: AlternateName[];
}

export const validateTitle = (argument: any) => {
  validateNotNull(argument, `Invalid Title type, object is null`);
  isPropertyWithVerify(
    argument.catalogue,
    validateAuthorityOf('title', validateCatalogueTitleInfo),
    'Invalid Title type, catalogue is not a CatalogueTitleInfo'
  );
  isArrayPropertyWithVerify(
    argument.alternateNames,
    validateAlternateName,
    'Invalid Title type, alternateNames is not a AlternateName[]'
  );
  return true;
};
