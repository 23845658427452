import {
  isArrayPropertyWithVerify,
  isNumber,
  isPropertyWithVerify,
  isString,
  validateNotNull,
} from 'domain/guards';
import { Authority, validateAuthorityOf } from 'domain/igniteTitle/authority';

export interface Episode {
  id: Nullable<string>;
  name: Nullable<string>;
  ccid?: string;
  slotLengthMinutes?: number;
  approximateRuntimeMinutes: Nullable<number>;
  indexInSeries: Nullable<number>;
  versionCcid?: string;
}

export interface SubSeries {
  name?: string;
  seriesCcid?: string;
}

export interface Configuration {
  episodes: Authority<Episode[]>;
}

export interface SecondaryConfiguration extends Configuration {
  name?: string;
  seriesCcid?: string;
  subSeries?: AuthoritySubSeries;
}
export type PrimaryConfiguration = Configuration;

export type AuthoritySubSeries = Authority<SubSeries>;

export interface ConfigurationData {
  productionYear: Nullable<number>;
}

export interface PrimaryConfigurationData extends ConfigurationData {
  configuration: PrimaryConfiguration;
}
export interface SecondaryConfigurationData extends ConfigurationData {
  configuration: SecondaryConfiguration;
}

export const validateEpisode = (argument: Episode) => {
  validateNotNull(argument, `Invalid episode type, object is null`);
  if (argument.name) {
    isPropertyWithVerify(
      argument.name,
      isString,
      `Invalid episode.name type, should be string`
    );
  }
  if (argument.ccid) {
    isPropertyWithVerify(
      argument.ccid,
      isString,
      `Invalid episode.ccid type, should be string`
    );
  }
  if (argument.slotLengthMinutes) {
    isPropertyWithVerify(
      argument.slotLengthMinutes,
      isNumber,
      `Invalid episode.slotLengthMinutes type, should be number`
    );
  }
  if (argument.approximateRuntimeMinutes) {
    isPropertyWithVerify(
      argument.approximateRuntimeMinutes,
      isNumber,
      `Invalid episode.approximateRuntimeMinutes type, should be number`
    );
  }
  if (argument.indexInSeries) {
    isPropertyWithVerify(
      argument.indexInSeries,
      isNumber,
      `Invalid episode.indexInSeries type, should be number`
    );
  }
  if (argument.versionCcid) {
    isPropertyWithVerify(
      argument.versionCcid,
      isString,
      `Invalid episode.versionCcid type, should be a string`
    );
  }
  return true;
};

const validateSubSeries = (argument: SubSeries) => {
  if (argument.name) {
    isPropertyWithVerify(
      argument.name,
      isString,
      `Invalid version.name type, should be string`
    );
  }
  if (argument.seriesCcid) {
    isPropertyWithVerify(
      argument.seriesCcid,
      isString,
      `Invalid version.ccid type, should be string`
    );
  }
  return true;
};

export const validatePrimaryConfiguration = (
  argument: PrimaryConfiguration
) => {
  validateAuthorityOf('primaryConfiguration.episodes', validateEpisode);

  isArrayPropertyWithVerify(
    argument.episodes.value,
    validateEpisode,
    `Invalid primaryConfiguration.episodes type, should be episodes`
  );
  return true;
};

export const validateSecondaryConfiguration = (
  argument: SecondaryConfiguration
) => {
  validateNotNull(
    argument,
    `Invalid secondaryConfiguration type, object is null`
  );

  if (argument.seriesCcid) {
    isPropertyWithVerify(
      argument.seriesCcid,
      isString,
      `Invalid secondaryConfiguration.ccid type, should be string`
    );
  }

  if (argument.subSeries) {
    isPropertyWithVerify(
      argument.subSeries,
      validateAuthorityOf(
        'secondaryConfiguration.subSeries',
        validateSubSeries
      ),
      `Invalid secondaryConfiguration.subSeries type, should be subSeries`
    );
  }

  validateAuthorityOf('secondaryConfiguration.episodes', validateEpisode);

  isArrayPropertyWithVerify(
    argument.episodes.value,
    validateEpisode,
    `Invalid secondaryConfiguration.episodes type, should be episodes`
  );
  return true;
};
