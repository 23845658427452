export const toggleIn = (
  array: string[],
  item: string | string[],
  multiSelect: boolean
) => {
  const items = Array.isArray(item) ? item : [item];
  const newItems = items.every((targetItem) => array.includes(targetItem))
    ? array.filter((itemInArray) => !items.includes(itemInArray))
    : array.concat(items);
  return multiSelect ? newItems : [newItems[newItems.length - 1]]; // Return the most recent selection if its single select
};
