import { hasNonEmptyStringProperty, validateNotNull } from '../../../../guards';

export interface CatalogueTitleInfo {
  name: string;
  ccid?: string;
}

export const validateCatalogueTitleInfo = (argument: any) => {
  validateNotNull(argument, `Invalid CatalogueTitleInfo type, object is null`);
  hasNonEmptyStringProperty(argument, 'catalogueTitleInfo', 'name');
  if (argument.ccid != null) {
    hasNonEmptyStringProperty(argument, 'catalogueTitleInfo', 'ccid');
  }
  return true;
};
