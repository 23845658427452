/* eslint-disable @typescript-eslint/no-use-before-define */
export const validateNotNull = (input: any, err: string) => {
  if (input == null) {
    throw new TypeError(err);
    // `Invalid ${type} type, ${property} is null`
  }
};

export const validateIsObject = (input: any, err: string) => {
  if (!isObject(input)) {
    throw new TypeError(err);
  }
};

export const hasNonEmptyStringProperty = (
  argument: Record<string, any>,
  type: string,
  property: string
) => {
  validateNotNull(
    argument[property],
    `Invalid ${type} type, ${property} is null`
  );
  isPropertyWithVerify(
    argument[property],
    isString,
    `Invalid ${type} type, ${property} is not a string`
  );
  if (argument[property].trim() === '') {
    throw new TypeError(`Invalid ${type} type, ${property} is an empty string`);
  }
  return true;
};

export const hasPropertyWithValue = (
  argument: Record<string, any>,
  type: string,
  property: string,
  value: any
) => {
  validateNotNull(
    argument[property],
    `Invalid ${type} type, ${property} is null`
  );

  if (argument[property] !== value) {
    throw new TypeError(
      `Invalid ${type} type, ${argument[property]} was not ${value}`
    );
  }

  return true;
};

export const isPropertyWithVerify = <T>(
  argument: T,
  checkType: (arg: T) => boolean,
  errorMessage: string
) => {
  let result: boolean;
  try {
    result = checkType(argument);
  } catch (err) {
    const caughtMessage = (err as any).message;
    if (caughtMessage) {
      throw new TypeError(`${errorMessage}, ${caughtMessage}`);
    }
    throw new TypeError(errorMessage);
  }

  if (!result) {
    throw new TypeError(errorMessage);
  }

  return true;
};

export const hasArrayProperty = (
  argument: Record<string, any>,
  type: string,
  property: string
) => {
  validateNotNull(
    argument[property],
    `Invalid ${type} type, ${property} is null`
  );
  if (!Array.isArray(argument[property])) {
    throw new TypeError(
      `Invalid ${type} type, ${argument[property]} was not an array`
    );
  }
  return true;
};

export const isArrayOf = (argument: Record<string, any>, type: string) => {
  validateNotNull(argument, `Invalid ${type}[] type, argument is null`);
  if (!Array.isArray(argument)) {
    throw new TypeError(`Invalid ${type} type, ${argument} is not an array`);
  }
  return true;
};

export const isArrayOfWithVerify = <T>(
  argument: Record<string, any>,
  type: string,
  checkType: (arg: T) => boolean
) => {
  isArrayOf(argument, type);
  if (!argument.every(checkType)) {
    throw new TypeError(
      `Invalid ${type} type, ${argument} was not an array of ${type}`
    );
  }
  return true;
};

export const hasNonEmptyArrayProperty = (
  argument: Record<string, any>,
  type: string,
  property: string
) => {
  hasArrayProperty(argument, type, property);
  if (argument[property].length < 1) {
    throw new TypeError(`Invalid ${type} type, ${property} was an empty array`);
  }
  return true;
};

// todo: check usage
export const hasNonEmptyArrayPropertyWithVerify = <T>(
  argument: any,
  type: string,
  property: string,
  checkType: (arg: T) => boolean,
  expectedArgumentType: string
) => {
  hasNonEmptyArrayProperty(argument, type, property);
  if (argument[property].some((element: any) => element == null)) {
    throw new TypeError(
      `Invalid ${type} type, ${property} contains null values`
    );
  }
  if (!argument[property].every(checkType)) {
    throw new TypeError(
      `Invalid ${type} type, ${property} was not an array of ${expectedArgumentType}`
    );
  }
  return true;
};

export const isArrayPropertyWithVerify = <T>(
  argument: T[],
  checkType: (arg: T) => boolean,
  errorMessage: string
) => {
  let result: boolean;
  try {
    result = Array.isArray(argument) && argument.every(checkType);
  } catch (err) {
    const caughtMessage = (err as any).message;
    if (caughtMessage) {
      throw new TypeError(`${errorMessage}, ${caughtMessage}`);
    }
    throw new TypeError(errorMessage);
  }

  if (!result) {
    throw new TypeError(errorMessage);
  }
  return result;
};

export const isNonEmptyArrayPropertyWithVerify = <T>(
  argument: T[],
  checkType: (arg: T) => boolean,
  errorMessage: string
) => {
  let result: boolean;

  try {
    result =
      Array.isArray(argument) &&
      argument.length > 0 &&
      isArrayPropertyWithVerify(argument, checkType, errorMessage);
  } catch {
    throw new TypeError(errorMessage);
  }

  if (argument.some((element: any) => element == null)) {
    throw new TypeError(errorMessage);
  }

  if (!result) {
    throw new TypeError(errorMessage);
  }
  return result;
};

export const validateOptional =
  <T>(checkType: (arg: T) => boolean) =>
  (argument: any) => {
    if (argument != null) {
      checkType(argument);
    }
    return true;
  };

export const isNumber: (argument: any) => boolean = (argument) =>
  typeof argument === 'number';

export const isString: (argument: any) => boolean = (argument) =>
  typeof argument === 'string';

export const isBoolean: (argument: any) => boolean = (argument) =>
  typeof argument === 'boolean';

export const isNonEmptyString: (argument: any) => boolean = (argument) =>
  typeof argument === 'string' && argument.trim() !== '';

export const isObject: (argument: any) => boolean = (argument) =>
  typeof argument === 'object';
