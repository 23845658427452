import { Currency, validateCurrency } from '../../../datasets';
import {
  isNumber,
  isPropertyWithVerify,
  validateNotNull,
} from '../../../guards';

export interface MonetaryAmount {
  currency: Currency;
  amount: number;
}

export const validateMonetaryAmount = (argument: any) => {
  validateNotNull(argument, `Invalid MonetaryAmount type, object is null`);
  isPropertyWithVerify(
    argument.currency,
    validateCurrency,
    'Invalid MonetaryAmount type, currency is not a Currency'
  );
  isPropertyWithVerify(
    argument.amount,
    isNumber,
    'Invalid MonetaryAmount type, amount is not a number'
  );
  return true;
};
