import { styled } from '@mui/material';

import { useDashboardTitles } from 'hooks/useDashboardTitles';
import { Fragment } from 'react';
import { IgniteTitleMin } from 'domain/igniteTitle/titles';
import { InfiniteScroller } from 'components/InfiniteScroller';
import {
  ListEmpty,
  ListError,
  ListLoading,
  ListLoadingSingle,
  TitleRow,
  TitlesTableHeader,
} from '../components';

const StyledTitlesTable = styled('table')`
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  border-collapse: separate;
  border-spacing: 0 1px;
`;

const TitlesTable = () => {
  const { data, status, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useDashboardTitles();

  const pages = data?.pages;
  const isLoading = status === 'pending';
  const hasError = status === 'error';
  const isListEmpty = status === 'success' && pages?.[0].length === 0;
  const isDataReady =
    status === 'success' && pages?.[0] && pages?.[0].length > 0;

  if (!isDataReady && !isListEmpty && !hasError) {
    return (
      <StyledTitlesTable>
        <TitlesTableHeader />
        <ListLoading />
      </StyledTitlesTable>
    );
  }

  return (
    <StyledTitlesTable aria-label="Titles">
      <TitlesTableHeader />
      {isLoading && <ListLoading />}
      {hasError && <ListError />}
      {isListEmpty && <ListEmpty />}
      {isDataReady && (
        <InfiniteScroller
          Element="tbody"
          loadMore={() => isFetchingNextPage || fetchNextPage()}
          hasMore={hasNextPage}
          loader={<ListLoadingSingle key="loadingRow" />}
        >
          {pages?.map((titles: IgniteTitleMin[]) => (
            <Fragment key={`${titles[0]?.id || 'empty'}-pages`}>
              {titles.map((title) => {
                return <TitleRow key={title.id} title={title} />;
              })}
            </Fragment>
          ))}
        </InfiniteScroller>
      )}
    </StyledTitlesTable>
  );
};

export { TitlesTable };
