import { useDatasets } from 'hooks/useDatasets';
import { UIFilter } from '../UIFilter';

export const Team = () => {
  const { datasets } = useDatasets();
  const options =
    datasets?.teams?.map(({ name }) => ({
      name,
      value: name,
    })) ?? [];

  return <UIFilter filterKey="team" filterLabel="Team" options={options} />;
};
