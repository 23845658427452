import { styled } from '@mui/material';

import { useAuthDispatch } from 'containers/App/Context/authContext';
import voidSession from 'utils/voidSession';
import { deleteFromStorage } from '@rehooks/local-storage';
import { useUserProfile } from 'hooks/useUserProfile';

const StyledLogoutButton = styled('button')`
  color: white;
  font-size: 16px;
  text-decoration: none;
  border: none;
  background: none;
  :hover {
    cursor: pointer;
  }
`;

export const LogoutButton = () => {
  const dispatch = useAuthDispatch();
  const { refetch } = useUserProfile();
  const logout = async () => {
    await voidSession();
    deleteFromStorage('redirectTo');
    dispatch({ type: 'LOGOUT' });
    refetch();
  };
  return <StyledLogoutButton onClick={logout}>Logout</StyledLogoutButton>;
};
