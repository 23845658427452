import { ArrayParam, useQueryParam, withDefault } from 'use-query-params';
import { useLocalStorage } from '@rehooks/local-storage';
import { toggleIn } from 'utils/toggleIn';
import { useEffect } from 'react';
import { removeIn } from 'utils/removeIn';

type ParamValue = string | string[];

const nullishFilter = (value: string | null) =>
  Boolean(value?.toString().trim());

const useFilterParam = (filterKey: string, multiSelect: boolean) => {
  const [cachedValues, setCachedValues] = useLocalStorage<(string | null)[]>(
    `filter_${filterKey}`,
    []
  );

  const [paramValues, setParamValue] = useQueryParam(
    filterKey,
    withDefault(ArrayParam, [])
  );

  useEffect(() => {
    if (!paramValues.length && cachedValues?.length) {
      setParamValue(cachedValues.filter(nullishFilter));
    }
    const filteredParamValues = paramValues.filter(nullishFilter);
    if (paramValues.length !== filteredParamValues.length) {
      setParamValue(filteredParamValues);
    }
  }, [cachedValues, setParamValue, paramValues]);

  const toggleParamValue = (paramValue: ParamValue) => {
    const nextValues = toggleIn(
      paramValues as string[],
      paramValue,
      multiSelect
    );
    setParamValue(nextValues);
    setCachedValues(nextValues);
  };

  const addParamValue = (paramValue: ParamValue) => {
    const nextValues = [...new Set([...paramValues, paramValue])].flat();
    setParamValue(nextValues);
    setCachedValues(nextValues);
  };

  const removeParamValue = (paramValue: ParamValue) => {
    const nextValues = removeIn(paramValues as string[], paramValue);
    setParamValue(nextValues);
    setCachedValues(nextValues);
  };

  const clearParam = () => {
    setParamValue([]);
    setCachedValues([]);
  };

  return {
    toggleParamValue,
    addParamValue,
    removeParamValue,
    clearParam,
    paramValues,
  };
};

export { useFilterParam };
export default useFilterParam;
