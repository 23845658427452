import { lazy, Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Snackbar } from 'components/Snackbar';
import { RequireAuth } from 'components/RequireAuth';
import { IgniteVersionSnackbar } from 'features/Version';
import { Nav } from 'components/Nav';
import { TitleDashboardLoading } from '../TitleDashboard';

const Dashboard = lazy(() => import('../TitleDashboard'));
const TitlePage = lazy(() => import('../TitlePage'));
const AppProviders = lazy(() => import('./Context'));
const LoginPage = lazy(() => import('../LoginPage'));
const FetchSession = lazy(() => import('../FetchSession'));
const ErrorPage = lazy(() => import('../ErrorPage'));

const Layout = () => (
  <RequireAuth>
    <>
      <Nav />
      <Suspense fallback={<TitleDashboardLoading />}>
        <main>
          <Outlet />
        </main>
      </Suspense>
    </>
  </RequireAuth>
);

const App = () => {
  return (
    <AppProviders>
      <Snackbar />
      <IgniteVersionSnackbar />
      <Suspense fallback={null}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Navigate to="dashboard" replace />} />

            <Route path="/dashboard" element={<Dashboard />} />

            <Route path="titles/:titleId/*" element={<TitlePage />} />
          </Route>
          <Route path="login" element={<LoginPage />} />
          <Route path="okta/code" element={<FetchSession />} />
          <Route
            path="*"
            element={<ErrorPage error={new Error('Page Error: Not Found')} />}
          />
        </Routes>
      </Suspense>
    </AppProviders>
  );
};

export { App };
