import { fetchBusinesses } from 'api';
import { useQuery } from '@tanstack/react-query';
import { Businesses } from 'domain/business';

export const useBusinesses = (enabled = true) => {
  return useQuery<Businesses>({
    queryKey: ['businesses'],
    queryFn: fetchBusinesses,
    enabled,
  });
};
