import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getDomainEnv } from 'utils/getDomainEnv';
import { fetchIgniteVersion } from '../api';

export const useIgniteVersionChecker = (interval = 60000) => {
  const [version, setVersion] = useState<string | null>(null);
  const [hasVersionChanged, setHasVersionChanged] = useState(false);

  const response = useQuery({
    queryKey: ['igniteVersion'],
    queryFn: () => fetchIgniteVersion(),
    refetchInterval: interval,
    enabled: !hasVersionChanged && getDomainEnv() !== 'LOCAL',
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (response.data) {
      setHasVersionChanged(version !== null && version !== response.data);
      setVersion(response.data);
    }
  }, [response.data]);

  return {
    hasVersionChanged,
  };
};
