import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { styled, Button, TextField, Typography } from '@mui/material';
import { Box, Flex } from 'components/Layout';
import { useQueryParam } from 'use-query-params';
import { useSearch } from 'hooks/useSearch';
import { EventActions, useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { SearchIcon } from './SearchIcon';
import { ClearIcon } from './ClearIcon';

export interface SearchInputProps {
  onSubmit: (value: string) => void;
  onClear: () => void;
  paramValue?: string;
}

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    background-color: white;
  }
  text-overflow: 'ellipsis';
`;

const StyledResults = styled('p')`
  text-align: right;
  user-select: none;
  pointer-events: none;
  width: fit-content;
  white-space: nowrap;
  margin: 0;
`;

const SearchInput = ({
  onSubmit,
  onClear,
  paramValue = '',
}: SearchInputProps) => {
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchQueryParam] = useQueryParam('search');
  const { data } = useSearch();
  const searchHitTotal =
    searchQueryParam !== undefined ? data?.pages[0]?.totalHits || 0 : undefined;
  const { logDashboardSearchEvent } = useGoogleAnalytics();

  useEffect(() => {
    setSearchValue(paramValue);
  }, [paramValue]);

  const clearSearch = () => {
    setSearchValue('');
    onClear();
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (searchValue) {
          onSubmit(searchValue);
          logDashboardSearchEvent(
            EventActions.DashboardActions.Search,
            searchValue
          );
        } else {
          clearSearch();
        }
      }}
    >
      <Flex>
        <Box mr={2}>
          <StyledTextField
            id="search-input"
            inputRef={inputRef}
            placeholder="Search..."
            value={searchValue}
            role="search"
            size="small"
            InputProps={{
              startAdornment: <SearchIcon />,
              endAdornment: (
                <Flex justifyContent="center" alignItems="center" gap={3}>
                  <StyledResults hidden={searchHitTotal == null}>
                    {searchHitTotal} results
                  </StyledResults>
                  <ClearIcon
                    isVisible={!!searchValue}
                    onClear={clearSearch}
                    inputRef={inputRef}
                  />
                </Flex>
              ),
              onChange: (event: ChangeEvent<HTMLInputElement>) => {
                setSearchValue(event.target.value);
              },
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          sx={{
            padding: '0 20px',
          }}
          aria-label="Search"
        >
          <Typography
            component="span"
            variant="subtitle1"
            sx={{ fontSize: '1.1rem', paddingTop: '3px' }}
          >
            Search
          </Typography>
        </Button>
      </Flex>
    </form>
  );
};

export { SearchInput };
export default SearchInput;
