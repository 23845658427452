import { ErrorOutline } from '@mui/icons-material';
import { colours } from 'utils/theme/colours';
import { styled } from '@mui/material';

const StyledEmptyTitleRow = styled('tr')`
  text-align: center;
  background-color: transparent;
  color: ${colours.text.secondary};
  font-size: 13.33px;
  .error-header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    gap: 8px;
  }
  img {
    margin-top: -3px;
  }
`;

interface Props {
  visible?: boolean;
}

export const ListError = ({ visible = true }: Props) => {
  if (!visible) {
    return null;
  }
  return (
    <tbody>
      <StyledEmptyTitleRow style={{ background: 'none', marginTop: 200 }}>
        <td style={{ background: 'none', paddingTop: 72 }}>
          <div className="error-header">
            <ErrorOutline fontSize="small" />
            <span>Search Failed</span>
          </div>
          <div>Looks like there has been a technical issue.</div>
          <div>
            Please try again later. If the issue persists, feedback via the
            usual channels.
          </div>
        </td>
      </StyledEmptyTitleRow>
    </tbody>
  );
};
