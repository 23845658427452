import {
  hasNonEmptyStringProperty,
  isNumber,
  isBoolean,
  isPropertyWithVerify,
} from 'domain/guards';

export type Status = {
  name: string;
  index: number;
  description?: string;
  hasApprovalsForm?: boolean;
  requiresApprovalsForm?: boolean;
};

export const validateStatus = (argument: Status) => {
  hasNonEmptyStringProperty(argument, 'status', 'name');
  isPropertyWithVerify(
    argument.index,
    isNumber,
    'Invalid Status type, index is not a number'
  );
  if (argument.description != null) {
    hasNonEmptyStringProperty(argument, 'status', 'description');
  }
  if (argument.hasApprovalsForm != null) {
    isPropertyWithVerify(
      argument.hasApprovalsForm,
      isBoolean,
      'Invalid Status type, hasApprovalsForm is not a boolean'
    );
  }
  if (argument.requiresApprovalsForm != null) {
    isPropertyWithVerify(
      argument.requiresApprovalsForm,
      isBoolean,
      'Invalid Status type, requiresApprovalsForm is not a boolean'
    );
  }
  return true;
};
