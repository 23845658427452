import { styled } from '@mui/material';

import { Flex } from 'components/Layout';
import { extractMinTitleNames } from 'domain/igniteTitle/titleNameUtils';
import { IgniteTitleMin } from 'domain/igniteTitle/titles';
import Tooltip from '@mui/material/Tooltip';
import { textTruncate } from '../Styles';
import ProjectSubText from './ProjectSubText';
import TitleIcon from './TitleIcon';

const StyledProjectName = styled('div')`
  ${textTruncate}
`;

interface Props {
  title: IgniteTitleMin;
}

const ProjectName = ({ title }: Props) => {
  const { primaryName, subName } = extractMinTitleNames(title);
  return (
    <Flex
      alignItems="center"
      aria-label={`${title.titleType} - ${primaryName}, ${subName}`}
    >
      <Tooltip
        id="project-name-tooltip"
        placement="top"
        title={primaryName}
        arrow
      >
        <Flex>
          <Flex alignItems="center" aria-hidden>
            <TitleIcon title={title} />
          </Flex>
          <Flex
            flexDirection="column"
            overflow="hidden"
            ml={2}
            maxWidth="200px"
            justifyContent="center"
          >
            <StyledProjectName>{primaryName}</StyledProjectName>
            <ProjectSubText subText={subName} />
          </Flex>
        </Flex>
      </Tooltip>
    </Flex>
  );
};

export default ProjectName;

// one off title as main
// brand as subtext
