import { UIFilter } from '../UIFilter';

export const ReviewDate = () => {
  const options = [
    { value: 'future', name: 'Upcoming Review' },
    { value: 'past', name: 'Previous Review' },
  ];

  return (
    <UIFilter
      filterKey="targetPeriod"
      filterLabel="Review Date"
      options={options}
      multiSelect={false}
    />
  );
};
