import { IgniteTitleMin } from 'domain/igniteTitle/titles';
import { formatDateWithMode } from 'utils/date';

interface Props {
  title: IgniteTitleMin;
}

const TxDate = ({ title }: Props) => (
  <span
    aria-label={!title.transmission?.start ? 'No transmission date' : undefined}
  >
    {formatDateWithMode(title.transmission?.start)}
  </span>
);

export default TxDate;
