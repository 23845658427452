import {
  hasNonEmptyStringProperty,
  isNonEmptyString,
  isNumber,
  isPropertyWithVerify,
  validateNotNull,
} from '../../../../guards';

export interface SeriesDetails {
  seriesNumber?: number;
  seriesName?: string;
  seriesCCID?: string;
}

export const validateSeriesDetails = (argument: any) => {
  validateNotNull(argument, `Invalid SeriesDetails type, object is null`);
  if (argument.seriesNumber != null) {
    isPropertyWithVerify(
      argument.seriesNumber,
      isNumber,
      'Invalid SeriesDetails type, seriesNumber is not a number'
    );
  } else {
    try {
      isPropertyWithVerify(
        argument.seriesName,
        isNonEmptyString,
        'Invalid SeriesDetails type, seriesName is not a string'
      );
    } catch (e: any) {
      if (e.message === 'Invalid series type, seriesName is null') {
        throw new TypeError(
          'Invalid series type, seriesName and seriesNumber are both null. One is required.'
        );
      } else {
        throw e;
      }
    }
  }
  if (argument.seriesCCID != null) {
    hasNonEmptyStringProperty(argument, 'series', 'seriesCCID');
  }
  return true;
};
