import { Alert, AlertProps } from '@mui/material';
import MuiSnackbar, { SnackbarProps } from '@mui/material/Snackbar';
import { useCallback } from 'react';
import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';

interface SnackbarStore {
  snackbarProps: SnackbarProps;
  alertProps: AlertProps;
}

const snackbarStore = createStore<SnackbarStore>(() => ({
  snackbarProps: {},
  alertProps: {},
}));

export { snackbarStore };

export const Snackbar = () => {
  const useBoundStore = (
    selector: (selector: SnackbarStore) => SnackbarStore
  ) => useStore(snackbarStore, selector);

  const { snackbarProps, alertProps }: SnackbarStore = useBoundStore(
    (selector: SnackbarStore) => selector
  );

  const hideSnackbar = useCallback(() => {
    snackbarStore.setState({
      snackbarProps: { ...snackbarProps, open: false },
      alertProps: { ...alertProps },
    });
  }, [snackbarProps, alertProps]);

  return (
    <MuiSnackbar
      {...snackbarProps}
      open={snackbarProps.open}
      data-testid="notification-toast"
      autoHideDuration={3000}
      onClose={hideSnackbar}
    >
      <Alert variant="filled" {...alertProps} onClose={hideSnackbar}>
        {snackbarProps.message}
      </Alert>
    </MuiSnackbar>
  );
};

// This might be too restrictive for future tickets
const openSnackbar = (
  severity: 'error' | 'info' | 'success' | 'warning',
  message: string
) => {
  snackbarStore.setState({
    snackbarProps: { open: true, message },
    alertProps: { severity },
  });
};

export const snackbarVariants = {
  success: (message: string) => openSnackbar('success', message),
  error: (message: string) => openSnackbar('error', message),
  warning: (message: string) => openSnackbar('warning', message),
  info: (message: string) => openSnackbar('info', message),
};
