import { searchTitles } from 'api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { useLocation } from 'react-router-dom';

const resultsLimit = 15;

const useSearch = () => {
  const { pathname } = useLocation();
  const [queryParams] = useQueryParams({
    search: StringParam,
    team: ArrayParam,
    projectStatus: ArrayParam,
    productionStatus: ArrayParam,
    producerId: ArrayParam,
    showArchived: StringParam,
    targetPeriod: StringParam,
  });

  const fetchTitlesWithPageParam = ({ pageParam = 0 }) =>
    searchTitles(
      {
        term: queryParams.search || undefined,
        offset: pageParam,
        limit: resultsLimit,
      },
      queryParams
    );

  return useInfiniteQuery({
    queryKey: ['searchResults', queryParams],
    queryFn: fetchTitlesWithPageParam,
    initialPageParam: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    getNextPageParam: (lastPage) => {
      if (lastPage?.results?.length < resultsLimit) {
        return null;
      }
      // eslint-disable-next-line no-unsafe-optional-chaining
      return lastPage?.offset + resultsLimit;
    },
    enabled: pathname.includes('/dashboard'),
  });
};

export { useSearch };
