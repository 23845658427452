import { styled } from '@mui/material';
import { colours } from 'utils/theme/colours';
import { textTruncate } from '../../Styles';

const StyledProjectSubText = styled('div')`
  ${textTruncate}
  color: ${colours.secondary.main}
  font-size: 13px;
`;
interface Props {
  subText: string | undefined;
}

const ProjectSubText = ({ subText }: Props) => {
  if (subText) {
    return <StyledProjectSubText>{subText}</StyledProjectSubText>;
  }

  return null;
};

export default ProjectSubText;
