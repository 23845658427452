import { styled } from '@mui/material';
import { useUserProfile } from 'hooks/useUserProfile';
import { UserInfo } from 'domain/igniteTitle/userInfo';
import colours from 'utils/theme/colours';
import { textTruncate } from '../../Styles';

const LastUpdateBy = styled('div')`
  ${textTruncate}
  color: ${colours.secondary.main}
  font-size: 13px;
`;

interface Props {
  user: UserInfo;
}

const ModifiedBy = ({ user }: Props) => {
  const { data: userProfile } = useUserProfile();

  const isLastModifiedByYou =
    user.email.toLowerCase() === userProfile?.user.email.toLowerCase();

  return (
    <LastUpdateBy>
      {`By ${isLastModifiedByYou ? 'You' : user.name}`}
    </LastUpdateBy>
  );
};

export default ModifiedBy;
