import { hasNonEmptyStringProperty, validateNotNull } from '../guards';

export interface UserInfo {
  email: string;
  name: string;
}

export const validateUserInfo =
  (type: string, property: string): ((argument: any) => argument is UserInfo) =>
  (argument): argument is UserInfo => {
    validateNotNull(
      argument,
      `Invalid ${type} ${property} type, object is null`
    );
    hasNonEmptyStringProperty(argument, `${type} ${property}`, 'email');
    hasNonEmptyStringProperty(argument, `${type} ${property}`, 'name');
    return true;
  };
