import {
  validateNotNull,
  hasNonEmptyStringProperty,
  isNumber,
  isBoolean,
  isPropertyWithVerify,
} from 'domain/guards';

export type SubStatus = {
  name: string;
  index: number;
  description?: string;
  hasApprovalsForm: boolean;
  requiresApprovalsForm: boolean;
};

export const validateSubStatus = (argument: SubStatus) => {
  validateNotNull(argument, `Invalid SubStatus type, object is null`);
  hasNonEmptyStringProperty(argument, 'substatus', 'name');
  isPropertyWithVerify(
    argument.index,
    isNumber,
    'Invalid SubStatus type, index is not a number'
  );
  if (argument.description != null) {
    hasNonEmptyStringProperty(argument, 'substatus', 'description');
  }
  isPropertyWithVerify(
    argument.hasApprovalsForm,
    isBoolean,
    'Invalid SubStatus type, hasApprovalsForm is not a boolean'
  );
  isPropertyWithVerify(
    argument.requiresApprovalsForm,
    isBoolean,
    'Invalid SubStatus type, requiresApprovalsForm is not a boolean'
  );
  return true;
};
