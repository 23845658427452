import { styled } from '@mui/material';

import { useSearch } from 'hooks/useSearch';
import { useMemo } from 'react';
import { IgniteTitle } from 'domain/igniteTitle/titles';
import { InfiniteScroller } from 'components/InfiniteScroller';
import {
  ListContent,
  ListEmpty,
  ListError,
  ListLoading,
  ListLoadingSingle,
  TitleRow,
  TitlesTableHeader,
} from '../components';

const StyledSearchResults = styled('table')`
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  border-collapse: separate;
  border-spacing: 0 1px;
`;

const SearchResults = () => {
  const { data, status, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useSearch();

  const results = useMemo<IgniteTitle[]>(
    () =>
      data?.pages.reduce((acc, page) => acc.concat(page.results as any), []) ||
      [],
    [data]
  );

  return (
    <StyledSearchResults>
      <TitlesTableHeader
        resultsOfASearch={data?.pages[0]?.totalHits !== undefined}
      />
      <ListLoading visible={status === 'pending'} />
      <ListError visible={status === 'error'} />
      <ListEmpty visible={status === 'success' && results.length === 0} />
      <ListContent visible={status === 'success' && results.length > 0}>
        <InfiniteScroller
          Element="tbody"
          loadMore={() => isFetchingNextPage || fetchNextPage()}
          hasMore={hasNextPage}
          loader={<ListLoadingSingle key="loadingRowSearch" />}
        >
          {results.map((title) => {
            return <TitleRow key={title.id} title={title as any} />;
          })}
        </InfiniteScroller>
      </ListContent>
    </StyledSearchResults>
  );
};

export { SearchResults };
