import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { snackbarVariants } from 'components/Snackbar';
import { useNavigate } from 'react-router-dom';

export const handleError = (error: AxiosError<AxiosError, any>) => {
  const errorMessage = error.response?.data?.message || error.message;
  const { status } = error.response || {};

  console.error(errorMessage);
  console.error(error);

  if (!error.response) {
    snackbarVariants.error(
      'Network Error - Please check your internet connection'
    );
  }

  if (status === 400 || status === 500) {
    Sentry.captureException(error);
    snackbarVariants.error('Network Error - Please contact support');
  }

  if (status === 401) {
    redirectToLoginAndClearUserData();
  }

  if (status === 422) {
    snackbarVariants.error(
      "We couldn't complete your request due to an unexpected error. Please contact support for further assistance."
    );
  }

  return Promise.reject(error);
};

const redirectToLoginAndClearUserData = () => {
  localStorage.removeItem('userprofile');

  try {
    const navigate = useNavigate();
    navigate('/login');
  } catch {
    window.location.assign('/login');
  }
};
