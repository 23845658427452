import { format } from 'date-fns';
import { IgniteTitleMin } from 'domain/igniteTitle/titles';

interface Props {
  title: IgniteTitleMin;
}

const TargetDate = ({ title }: Props) => (
  <div aria-label={!title.targetDate ? 'No target date' : undefined}>
    {title.targetDate ? format(new Date(title.targetDate), 'dd MMM') : '-'}
  </div>
);

export default TargetDate;
