import { writeStorage } from '@rehooks/local-storage';

import api from 'api';

const getUserInfo = async () => {
  const userInfo = await api.fetchUserInfo();

  writeStorage('userprofile', userInfo);

  return userInfo;
};

export default getUserInfo;
